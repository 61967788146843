import React from 'react';
import {useProspectScoreSettingQuery, ClientPhaseProspect} from 'api';

export default () => {
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  return (
    <div className="flex flex-col gap-2">
      {Object.entries(
        prospectScoreSetting?.clientProspectScoreSettingPhaseProspectsByPriority ??
          {},
      )?.map(([priority, phaseProspects]: any) => {
        return (
          <div className="flex gap-2" key={priority}>
            <div className="font-bold text-sm">優先度{priority}</div>
            <div className="flex items-center gap-2 text-sm">
              {phaseProspects
                .map((phaseProspect: ClientPhaseProspect) => {
                  return phaseProspect.name;
                })
                ?.join(' | ')}
            </div>
          </div>
        );
      })}
    </div>
  );
};
