import React from 'react';
import styled from 'styled-components';
import {
  MailCampaignBlock,
  MailCampaignBlockTitle,
  MailCampaignBlockTitleAttributes,
  useUpdateMailCampaignBlockTitleMutation,
} from 'api';
import useDebounce from 'hooks/useDebounce';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Quill} from 'react-quill';
import {Form, Input} from 'components/antd';
import ColorInput from './ColorInput';
import PaddingInput from './PaddingInput';
import RichTextInput from './RichTextInput';

interface Block extends MailCampaignBlock {
  blockable: MailCampaignBlockTitle;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const {
    textHtml,
    bodyColor,
    textColor,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    lineHeight,
  } = block.blockable;

  const [update] = useUpdateMailCampaignBlockTitleMutation();

  const formik = useFormik({
    initialValues: {
      textHtml,
      bodyColor,
      textColor,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      lineHeight,
    },
    validationSchema: Yup.object().shape({
      textHtml: Yup.string().required('必須項目です'),
      bodyColor: Yup.string().nullable(),
      textColor: Yup.string().nullable(),
    }),
    onSubmit: (values) => {
      update({
        variables: {
          uuid: block.uuid,
          attributes: values,
        },
      });
    },
  });

  const debouncedValue = useDebounce<MailCampaignBlockTitleAttributes>(
    formik.values,
    500,
  );

  React.useEffect(() => {
    formik.submitForm();
  }, [debouncedValue]);

  const fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = [
    '10px',
    '11px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
  ];
  Quill.register(fontSizeStyle, true);

  const modules = React.useMemo(() => {
    return {
      toolbar: {
        container: [
          [
            {header: [1, 2, 3, 4, 5, 6, false]},
            'bold',
            'italic',
            'underline',
            {align: [false, 'center', 'right']},
            {color: []},
            {background: []},
          ],
        ],
      },
    };
  }, []);

  return (
    <Container>
      <h2>見出しを編集</h2>
      <Form layout="vertical">
        <Form.Item label="テキスト">
          <RichTextInput
            value={formik.values.textHtml}
            onChange={(value) => formik.setFieldValue('textHtml', value)}
            modules={modules}
          />
        </Form.Item>
        <Form.Item label="余白">
          <PaddingInput
            values={{
              paddingTop: formik.values.paddingTop,
              paddingBottom: formik.values.paddingBottom,
              paddingLeft: formik.values.paddingLeft,
              paddingRight: formik.values.paddingRight,
            }}
            onChange={(values) => {
              formik.setValues({...formik.values, ...values});
            }}
          />
        </Form.Item>
        <Form.Item label="行間">
          <Input
            type="number"
            step={0.1}
            minLength={1}
            maxLength={9.9}
            className="w-24"
            value={formik.values.lineHeight}
            onChange={(e) => {
              formik.setFieldValue(
                'lineHeight',
                parseFloat(e.target.value) || 1.5,
              );
            }}
          />
        </Form.Item>
        <Form.Item label="背景色">
          <ColorInput
            color={formik.values.bodyColor}
            onChange={(color) => formik.setFieldValue('bodyColor', color)}
          />
        </Form.Item>
        <Form.Item label="文字色">
          <ColorInput
            color={formik.values.textColor}
            onChange={(color) => formik.setFieldValue('textColor', color)}
          />
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  h2 {
    font-weight: 700;
    font-size: 16px;
  }

  label {
    font-size: 14px;
    color: var(--text-color-2);
    font-weight: 500;
  }
`;
