import React from 'react';
import {
  usePoolProspectCustomizeItemsQuery,
  useCreateClientProspectScoreSettingCustomizeItemMutation,
  useProspectScoreSettingQuery,
} from 'api';
import {Menu, Popover} from '@headlessui/react';

export default () => {
  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({});
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );
  const alreadyExistedOptions =
    prospectScoreSetting?.clientProspectScoreSettingCustomizeItems?.map(
      (item) => item?.poolProspectCustomizeItem?.id,
    );
  const availableOptions = React.useMemo(() => {
    return poolProspectCustomizeItems.filter(
      (item) =>
        item.dataType === 'select' && !alreadyExistedOptions?.includes(item.id),
    );
  }, [poolProspectCustomizeItems, alreadyExistedOptions]);
  const [create] = useCreateClientProspectScoreSettingCustomizeItemMutation({});
  return (
    <Popover as="div" className={'flex-none relative'}>
      <Popover.Button className="w-40 h-9 bg-blue-400 flex items-center justify-center cursor-pointer outline-none">
        <div className="flex items-center justify-center">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.5 4.99988H8.5M5 1.49988V8.49988V1.49988Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="text-white font-bold flex items-center text-center justify-center">
          カスタム項目を追加
        </div>
      </Popover.Button>
      <Popover.Panel className="absolute w-60 bg-white shadow-md left-0 z-10 mt-3 rounded-lg p-2 border-c-border border">
        {({close}) => (
          <div>
            {availableOptions.map((item) => {
              return (
                <div
                  className="h-7 text-gray-700 font-normal text-sm pl-4 py-2 cursor-pointer hover:bg-gray-50 flex items-center text-center"
                  onClick={() => {
                    create({
                      variables: {poolProspectCustomizeItemId: item.id},
                    });
                    close();
                  }}>
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
};
