import {KeyPerson} from 'api';
import React from 'react';
interface Props {
  keyPerson: KeyPerson;
}
export default ({keyPerson}: Props) => {
  if (!keyPerson.articles.length) return null;
  return (
    <div className="pb-2">
      <div className="px-2 bg-c-bg text-[#666666]">掲載メディア</div>
      {keyPerson.articles?.map((article) => (
        <div className="flex justify-between py-1" key={article.id}>
          <a
            href={`${article.url}`}
            className="truncate underline"
            target="_blank">
            {article.caseStudy.siteName}
          </a>
          <div>{article.publishedDate}</div>
        </div>
      ))}
    </div>
  );
};
