import React from 'react';

function Icon() {
  return (
    <svg
      width="202"
      height="25"
      viewBox="0 0 202 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_2_25"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="202"
        height="26">
        <path d="M201.219 0H0V25.004H201.219V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2_25)">
        <path
          d="M152.18 24.6961H141.855C141.504 24.6861 141.171 24.5389 140.928 24.286C140.685 24.0331 140.551 23.6949 140.555 23.3441C140.555 19.7061 143.16 17.7881 145.963 16.4371C148.913 15.0111 150.388 14.4211 150.388 12.7501C150.388 11.3501 148.988 10.6851 147.02 10.6851C145.521 10.6075 144.04 11.0408 142.82 11.9141C142.631 12.0424 142.409 12.112 142.181 12.1141C142.016 12.1234 141.851 12.0994 141.696 12.0437C141.54 11.988 141.398 11.9015 141.277 11.7896C141.155 11.6776 141.058 11.5424 140.99 11.392C140.922 11.2416 140.885 11.0791 140.881 10.9141C140.881 9.95509 141.766 9.36509 143.266 8.75109C144.516 8.28245 145.839 8.04099 147.174 8.03809C150.788 8.03809 153.344 9.68509 153.344 12.7091C153.344 15.2411 151.623 16.6421 148.551 18.1661C145.871 19.4941 143.951 20.4521 143.951 22.0501H152.18C152.528 22.0546 152.86 22.1968 153.104 22.4457C153.348 22.6946 153.483 23.0298 153.48 23.3781C153.478 23.7249 153.342 24.0576 153.099 24.3053C152.856 24.5531 152.527 24.6965 152.18 24.7051"
          fill="#00AAEB"
        />
        <path
          d="M162.995 24.9898C158.395 24.9898 155.817 21.0078 155.817 16.5088C155.817 12.0098 158.398 8.02881 162.995 8.02881C167.592 8.02881 170.173 12.0108 170.173 16.5088C170.173 21.0068 167.592 24.9898 162.995 24.9898ZM162.995 10.6828C160.586 10.6828 158.767 12.9938 158.767 16.5088C158.767 20.0238 160.586 22.3348 162.995 22.3348C165.404 22.3348 167.223 20.0238 167.223 16.5088C167.223 12.9938 165.404 10.6828 162.995 10.6828Z"
          fill="#00AAEB"
        />
        <path
          d="M184.185 24.6961H173.861C173.51 24.6861 173.178 24.5389 172.934 24.286C172.691 24.0331 172.557 23.6949 172.561 23.3441C172.561 19.7061 175.166 17.7881 177.969 16.4371C180.919 15.0111 182.394 14.4211 182.394 12.7501C182.394 11.3501 180.994 10.6851 179.026 10.6851C177.527 10.6074 176.046 11.0407 174.826 11.9141C174.637 12.0424 174.415 12.112 174.187 12.1141C174.022 12.1234 173.857 12.0994 173.702 12.0437C173.547 11.988 173.404 11.9015 173.283 11.7896C173.161 11.6776 173.064 11.5424 172.996 11.392C172.928 11.2416 172.891 11.0791 172.887 10.9141C172.887 9.95509 173.772 9.36509 175.272 8.75109C176.522 8.28245 177.845 8.04099 179.18 8.03809C182.794 8.03809 185.35 9.68509 185.35 12.7091C185.35 15.2411 183.629 16.6421 180.557 18.1661C177.877 19.4941 175.957 20.4521 175.957 22.0501H184.192C184.54 22.0546 184.873 22.1968 185.116 22.4457C185.36 22.6946 185.495 23.0298 185.492 23.3781C185.491 23.7249 185.354 24.0576 185.111 24.3053C184.868 24.5531 184.539 24.6965 184.192 24.7051"
          fill="#00AAEB"
        />
        <path
          d="M134.483 6.48117C134.273 5.85717 133.951 4.86017 133.83 4.48817L133.794 4.37717C133.747 4.22286 133.65 4.08867 133.518 3.99604C133.386 3.9034 133.227 3.85768 133.066 3.86617H130.828C130.752 3.85738 130.675 3.86874 130.605 3.89911C130.535 3.92947 130.474 3.97778 130.428 4.03917C130.386 4.09853 130.36 4.1671 130.35 4.23903C130.341 4.31095 130.349 4.38409 130.374 4.45217C130.428 4.62517 130.882 5.98117 131.13 6.67017C131.191 6.83375 131.305 6.9721 131.454 7.06283C131.603 7.15357 131.778 7.19139 131.951 7.17017H133.951C134.269 7.17017 134.393 7.07017 134.458 6.98317C134.504 6.90866 134.53 6.82397 134.535 6.7367C134.54 6.64943 134.523 6.56232 134.486 6.48317"
          fill="black"
        />
        <path
          d="M129.389 4.35602C129.34 4.20655 129.242 4.07761 129.112 3.98946C128.982 3.90132 128.826 3.85895 128.669 3.86902H126.431C126.355 3.85968 126.279 3.87026 126.209 3.89974C126.138 3.92921 126.077 3.97654 126.031 4.03702C125.989 4.09719 125.961 4.16671 125.952 4.23966C125.942 4.31261 125.95 4.38683 125.975 4.45602C126.033 4.63702 126.485 5.99202 126.73 6.67302C126.79 6.83721 126.904 6.97612 127.053 7.06697C127.203 7.15782 127.379 7.19522 127.552 7.17302H129.562C129.654 7.18493 129.748 7.17432 129.835 7.14209C129.922 7.10987 130 7.057 130.062 6.98802C130.111 6.9138 130.14 6.82824 130.146 6.73961C130.152 6.65098 130.135 6.56227 130.097 6.48202C129.85 5.74502 129.43 4.48802 129.387 4.35502"
          fill="black"
        />
        <path
          d="M43.6689 22.1001C43.6697 22.6362 43.5115 23.1605 43.2143 23.6068C42.9172 24.053 42.4943 24.4011 41.9993 24.6071C41.5043 24.813 40.9594 24.8676 40.4333 24.7638C39.9073 24.6601 39.4239 24.4027 39.0442 24.0242C38.6644 23.6457 38.4054 23.1632 38.2999 22.6375C38.1945 22.1118 38.2472 21.5667 38.4515 21.071C38.6558 20.5753 39.0025 20.1513 39.4477 19.8527C39.893 19.554 40.4168 19.3941 40.9529 19.3931C41.672 19.392 42.3621 19.6766 42.8714 20.1842C43.3808 20.6918 43.6676 21.381 43.6689 22.1001Z"
          fill="#00AAEB"
        />
        <path
          d="M45.2849 2.84001C45.3977 2.58548 45.4563 2.31028 45.4571 2.03188C45.4578 1.75349 45.4006 1.47798 45.2892 1.22286C45.1778 0.967735 45.0145 0.738572 44.8098 0.549921C44.605 0.361269 44.3633 0.217251 44.0999 0.127011C43.5621 -0.0408786 42.9816 -0.00757065 42.4665 0.220733C41.9514 0.449036 41.5368 0.856772 41.2999 1.36801C40.3669 3.02901 35.5279 12.195 34.7169 13.715C33.9059 15.235 32.8839 17.407 32.0339 17.076C31.2539 16.776 30.3339 13.441 29.9409 12.489C29.2409 10.799 27.8309 8.78901 25.4709 9.30101C23.6709 9.68901 22.6409 11.109 21.0089 14.24C20.3534 15.6185 19.5932 16.9447 18.7349 18.207C17.0599 20.494 16.1149 16.736 15.5609 14.794C14.8179 12.187 13.4609 8.49401 10.5049 8.50201C8.85294 8.50201 7.09194 9.47201 4.89994 13.02C3.62694 15.092 0.63494 20.887 0.19294 21.919C0.0718272 22.1669 0.00252969 22.437 -0.0107198 22.7126C-0.0239692 22.9882 0.0191076 23.2636 0.115879 23.522C0.212651 23.7804 0.361086 24.0163 0.552117 24.2155C0.743148 24.4146 0.972764 24.5726 1.22694 24.68C1.74061 24.8895 2.31377 24.9009 2.83534 24.7119C3.35691 24.5229 3.78973 24.147 4.04994 23.657C6.14994 20.391 6.72894 19.116 7.80394 17.614C8.95594 16.014 10.5199 14.585 11.7179 18.424C12.4719 20.84 13.7889 24.802 17.3349 24.824C19.4689 24.839 21.6779 22.996 22.7119 21.239C23.8799 19.253 24.1739 18.192 25.0649 17.233C25.5199 16.745 26.1959 16.568 26.8359 17.987C27.4539 19.359 28.7359 23.695 31.0069 24.64C34.2199 25.974 37.2439 19.475 38.2879 17.403C39.1299 15.733 44.9139 3.71401 45.2879 2.83701"
          fill="#00AAEB"
        />
        <path
          d="M71.4059 4.6868C71.311 4.60235 71.2002 4.53756 71.0801 4.49619C70.9599 4.45482 70.8327 4.4377 70.7059 4.4458H70.7209L51.1819 4.4708C51.0854 4.47006 50.9898 4.49023 50.9017 4.52993C50.8137 4.56964 50.7353 4.62793 50.6719 4.70081C50.5621 4.8319 50.5026 4.9978 50.5039 5.1688V7.64281C50.5081 7.81974 50.5814 7.98801 50.7082 8.11156C50.8349 8.23511 51.0049 8.30412 51.1819 8.3038H51.2919L65.5329 8.2848H66.1109C66.5259 8.2848 66.6409 8.3598 66.7389 8.4848C66.9539 8.7678 66.7309 9.21681 66.7009 9.32381C66.2907 10.9366 65.5487 12.4461 64.5223 13.7561C63.4959 15.066 62.2077 16.1476 60.7399 16.9318L60.5729 17.0208L60.4609 16.8678C59.7339 15.8678 59.1879 15.1818 58.5559 14.3848L58.4659 14.2658C58.3674 14.1181 58.2377 13.9938 58.0861 13.9016C57.9344 13.8093 57.7644 13.7514 57.5879 13.7318H54.2879C54.1914 13.7255 54.0953 13.7498 54.0134 13.8014C53.9315 13.8529 53.868 13.929 53.8319 14.0188C53.7983 14.1024 53.7853 14.1928 53.794 14.2824C53.8027 14.3721 53.8329 14.4583 53.8819 14.5338C56.3059 17.6382 58.4794 20.9302 60.3819 24.3788C60.4519 24.5134 60.558 24.6258 60.6884 24.7034C60.8188 24.7809 60.9682 24.8206 61.1199 24.8178H64.6959C64.7805 24.8179 64.8632 24.7934 64.9342 24.7475C65.0052 24.7015 65.0614 24.636 65.0959 24.5588C65.1375 24.4851 65.1593 24.4019 65.1593 24.3173C65.1593 24.2327 65.1375 24.1495 65.0959 24.0758C64.4984 22.9164 63.8236 21.7986 63.0759 20.7298L62.9559 20.5528L63.1319 20.4298C67.3119 17.4908 71.2219 13.7468 71.5919 5.2758C71.6045 5.16873 71.595 5.06021 71.5638 4.95698C71.5327 4.85376 71.4806 4.75805 71.4109 4.6758"
          fill="black"
        />
        <path
          d="M91.4949 4.40387C91.4295 4.32858 91.348 4.26899 91.2564 4.22952C91.1649 4.19005 91.0656 4.17171 90.9659 4.17587C90.5909 4.17587 88.8099 4.18887 87.7249 4.19287C87.5622 4.20287 87.405 4.25554 87.2691 4.3456C87.1333 4.43566 87.0235 4.55992 86.9509 4.70587C85.0969 8.95187 79.7159 12.1569 72.3209 13.3929C71.8429 13.4869 71.6139 13.7679 71.6039 14.0929V16.6509C71.6018 16.7435 71.6201 16.8355 71.6574 16.9203C71.6947 17.0052 71.7502 17.0808 71.8199 17.1419C71.9011 17.2134 71.9966 17.2667 72.1001 17.2983C72.2036 17.3298 72.3126 17.3389 72.4199 17.3249C75.3464 17.0494 78.2131 16.3259 80.9199 15.1799L81.2279 15.0379L81.2399 24.1149C81.2397 24.2059 81.2579 24.2961 81.2932 24.38C81.3286 24.4639 81.3805 24.5398 81.4458 24.6032C81.5111 24.6667 81.5885 24.7163 81.6734 24.7492C81.7583 24.7822 81.8489 24.7977 81.9399 24.7949H82.0849H84.7319C84.8985 24.7882 85.0567 24.7198 85.1758 24.603C85.2948 24.4862 85.3661 24.3293 85.3759 24.1629L85.3589 13.0919L85.4589 13.0269C88.3228 11.0658 90.4791 8.2348 91.6089 4.95287C91.6309 4.85983 91.6318 4.76305 91.6116 4.66962C91.5913 4.57618 91.5505 4.48846 91.4919 4.41287"
          fill="black"
        />
        <path
          d="M106.443 13.2521L106.454 13.0461L112.698 13.0291C112.787 13.0281 112.876 13.0095 112.958 12.9744C113.04 12.9392 113.115 12.8881 113.177 12.8241C113.294 12.6993 113.363 12.5369 113.371 12.3661V9.90408C113.373 9.81394 113.357 9.72435 113.323 9.64065C113.29 9.55695 113.24 9.48086 113.176 9.41692C113.112 9.35299 113.037 9.30252 112.953 9.26852C112.87 9.23453 112.78 9.21771 112.69 9.21908H112.622H106.491V4.83208C106.491 4.74008 106.473 4.64893 106.437 4.56418C106.402 4.47943 106.349 4.40284 106.283 4.33906C106.216 4.27528 106.138 4.22564 106.052 4.19315C105.966 4.16067 105.874 4.14601 105.782 4.15008H105.682H103.022C102.929 4.14911 102.838 4.16766 102.753 4.20454C102.668 4.24141 102.592 4.29577 102.529 4.36408C102.413 4.48984 102.349 4.65431 102.349 4.82508L102.355 9.22508L92.987 9.23508C92.8916 9.23219 92.7966 9.24919 92.7081 9.285C92.6196 9.32081 92.5396 9.37465 92.473 9.44308C92.3471 9.57637 92.2785 9.75375 92.282 9.93708V12.4101C92.2842 12.4993 92.3041 12.5873 92.3406 12.6688C92.3771 12.7502 92.4295 12.8236 92.4946 12.8847C92.5598 12.9457 92.6364 12.9932 92.7201 13.0244C92.8037 13.0555 92.8928 13.0697 92.982 13.0661H93.072L102.324 13.0481L102.309 13.2801C101.98 18.6381 99.409 20.1671 94.582 21.1561C94.161 21.2561 93.921 21.4631 93.921 21.8301V24.3761C93.9191 24.464 93.9375 24.5511 93.9748 24.6307C94.0121 24.7104 94.0672 24.7803 94.136 24.8351C94.2786 24.9523 94.4608 25.0103 94.645 24.9971C102.734 23.8781 106.033 20.4911 106.445 13.2481"
          fill="black"
        />
        <path
          d="M134.157 9.38019C134.037 9.26722 133.88 9.20241 133.716 9.19819H133.601L120.053 9.21719L120.045 4.82919C120.047 4.73849 120.031 4.64831 119.998 4.56385C119.965 4.4794 119.915 4.40236 119.852 4.33719C119.783 4.26897 119.702 4.21544 119.612 4.17985C119.522 4.14426 119.426 4.12736 119.329 4.13019H119.308H116.719C116.63 4.13071 116.542 4.14878 116.46 4.18336C116.378 4.21794 116.304 4.26836 116.241 4.33171C116.179 4.39507 116.13 4.47012 116.096 4.55257C116.063 4.63502 116.046 4.72324 116.047 4.81219L116.068 19.5182C116.068 23.1782 117.512 24.7182 121.531 24.7182H121.853L134.067 24.6962C134.235 24.6841 134.393 24.6098 134.509 24.4878C134.626 24.3658 134.693 24.2048 134.697 24.0362V21.5362C134.698 21.447 134.681 21.3586 134.647 21.276C134.614 21.1933 134.564 21.1181 134.502 21.0546C134.439 20.9911 134.365 20.9405 134.282 20.9058C134.2 20.8711 134.112 20.8528 134.023 20.8522L122.867 20.8632H122.852C122.095 20.9574 121.327 20.8105 120.658 20.4432C120.115 19.9892 120.058 19.3342 120.072 18.3102L120.065 13.0242L133.69 13.0042C133.78 13.0053 133.87 12.9882 133.953 12.954C134.037 12.9198 134.113 12.8692 134.177 12.8051C134.24 12.7411 134.291 12.6649 134.324 12.5811C134.358 12.4972 134.375 12.4075 134.373 12.3172V9.86519C134.375 9.77402 134.357 9.68351 134.321 9.59959C134.286 9.51567 134.233 9.44023 134.166 9.37819"
          fill="black"
        />
        <path
          d="M191.165 16.1639C191.733 15.5944 192.413 15.1492 193.162 14.8565C193.911 14.5639 194.712 14.4302 195.516 14.4639C199.056 14.4639 201.216 16.7499 201.216 19.6509C201.216 22.6989 198.537 24.9849 194.85 24.9849C190.4 24.9849 188.065 22.3299 188.065 18.8399C188.065 13.9979 190.621 11.1949 193.62 9.30193C194.725 8.54226 196.017 8.10022 197.356 8.02393C198.044 8.02393 198.556 8.39293 198.556 9.05693C198.556 9.76993 198.015 10.0899 196.934 10.5809C194.599 11.6379 192.116 13.3579 191.157 16.1609M194.893 16.9469C192.902 16.9469 191.378 18.0529 191.378 19.6999C191.378 21.4699 192.73 22.4999 194.795 22.4999C196.614 22.4999 198.261 21.4669 198.261 19.6729C198.261 18.0729 197.106 16.9439 194.893 16.9439"
          fill="#00AAEB"
        />
      </g>
    </svg>
  );
}

export default Icon;
