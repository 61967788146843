import React from 'react';
import {MailCampaignBlock, MailCampaignBlockTitle} from 'api';

interface Block extends MailCampaignBlock {
  blockable: MailCampaignBlockTitle;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const {textHtml} = block.blockable;

  const style = {
    color: block.blockable?.textColor,
    backgroundColor: block.blockable?.bodyColor,
    paddingTop: block.blockable?.paddingTop || 0,
    paddingBottom: block.blockable?.paddingBottom || 0,
    paddingLeft: block.blockable?.paddingLeft || 0,
    paddingRight: block.blockable?.paddingRight || 0,
    lineHeight: block.blockable?.lineHeight || 1.5,
  };

  return <div style={style} dangerouslySetInnerHTML={{__html: textHtml}} />;
};
