import React from 'react';
import moment from 'moment';
import { Airplane } from 'components/Ui/Icon';
import { Activity, BlastengineMailCampaignMailClickActivity, MailCampaignMailClickActivity } from 'api';
import useProjectName from './useProjectName';
import { Link } from 'react-router-dom';

interface BlastenigneMailCampaignActivity extends Activity {
  resource: BlastengineMailCampaignMailClickActivity;
}

interface Props {
  activity: BlastenigneMailCampaignActivity;
}

const MailCampaignMailClickActivityComponent = ({ activity }: Props) => {
  const { projectName } = useProjectName(activity);

  return (
    <div className="ml-[-15px] flex w-[480px]">
      <div className="relative z-1 flex w-[30px] h-[30px] m-auto mr-[20px] before:absolute before:top-0 before:bottom-[-10px] before:left-0 before:w-[1px] before:content-[''] before:bg-[#e1e6eb] before:z-[-1]">
        <Airplane />
      </div>
      <div className="min-w-0 max-w-full mt-1 flex-auto flex flex-col text-[10px] leading-[14px] space-y-1">
        {projectName && <div className="text-color-2">{projectName}</div>}
        <div className="text-color-2">
          <Link
            target="_blank"
            to={`/mail_campaigns/${activity.resource.mailCampaignSend.mailCampaign.uuid}`}>
            {activity.resource.mailCampaignSend.mailCampaign.title}
          </Link>
        </div>
        <a href={activity.resource.mailCampaignUrl.url} target="_blank">
          {activity.resource.mailCampaignUrl.url}
        </a>
      </div>
      <span className="mt-auto ml-auto">
        {moment(activity.createdAt).format('MM/DD HH:mm')}
      </span>
    </div>
  );
};

export default MailCampaignMailClickActivityComponent;
