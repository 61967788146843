import React from 'react';

interface Props {
  department: string;
}

export default ({department}: Props) => {
  if (!department) return null;
  return (
    <div className="text-xs text-c-light truncate leading-none mb-2">
      {department}
    </div>
  );
};
