import React from 'react';
import {PencilSquareIcon} from '@heroicons/react/20/solid';
import {Popover} from 'components/antd';
import {useAccountPoolQuery, PreleadTag} from 'api';
import {useParams} from 'react-router-dom';
import TagSelect from './TagSelect';

export default () => {
  const {accountId} = useParams<{
    poolId: string;
    accountId: string;
  }>();
  const [isPopoverVisible, setPopoverVisible] = React.useState(false);
  const {data: {accountPool = {}} = {}} = useAccountPoolQuery({
    variables: {uuid: accountId},
    skip: !accountId,
  });
  const accountPoolPreleadTags: PreleadTag[] = accountPool.preleadTags || [];

  return (
    <div className="relative inline-block text-left">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-c-light">タグ</div>
          <div className="flex gap-2 flex-wrap items-center max-h-[12em] overflow-y-auto">
            {accountPoolPreleadTags?.map((tag) => {
              return (
                <span
                  key={tag.id}
                  style={{background: tag.color}}
                  className="px-3 py-1 rounded text-sm leading-none font-bold tracking-tight text-white">
                  {tag.name}
                </span>
              );
            })}
          </div>
        </div>

        <Popover
          content={() => <TagSelect />}
          trigger="click"
          visible={isPopoverVisible}
          onVisibleChange={(visible) => setPopoverVisible(visible)}
          placement="bottomLeft">
          <button
            className="cursor-pointer bg-transparent border-none outline-none flex items-center"
            onClick={() => setPopoverVisible(true)}>
            <PencilSquareIcon className="w-5 h-5 text-c-primary" />
          </button>
        </Popover>
      </div>
    </div>
  );
};
