import React from 'react';
import styled from 'styled-components';
import {ProspectPool, useCurrentClientQuery} from 'api';
import {ActionPagination} from '../Pagination';
import {
  Archive,
  Miitel,
  OptoutMail,
  Search,
  BounceMail,
} from 'components/Ui/Icon';
import {miitelCall} from '../useMiitel';
import Tag from './Tag';
import Copy from './Copy';
import Flags from './Flags';
import Menu from './Menu';
import SalesForce from './Salesforce';
import Account from './Account';
import ProspectScore from 'components/ProspectScore';
import UtmParameter from './UtmParameter';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery({
    fetchPolicy: 'cache-and-network',
  });
  const account = prospectPool.prospect.account;

  if (!prospectPool) return null;

  return (
    <Container>
      {location.pathname.includes('/actions/') && <ActionPagination />}
      <Flags prospectPool={prospectPool} />
      <ProspectNameWrapper>
        <div>
          <ProspectScore prospectPool={prospectPool} />
          <ProspectName>
            {prospectPool.prospect.lastName} {prospectPool.prospect.firstName}
          </ProspectName>
          <Copy
            copyText={
              prospectPool.prospect.lastName +
              ' ' +
              prospectPool.prospect.firstName
            }
          />
          {prospectPool.isUnsubscribed && (
            <Unsubscribed>
              <OptoutMail />
              配信停止
            </Unsubscribed>
          )}
          {prospectPool.isBounced && (
            <Bounced>
              <BounceMail />
              バウンス
            </Bounced>
          )}
          {prospectPool.status === 'archived' && (
            <Unsubscribed>
              <Archive />
              アーカイブ
            </Unsubscribed>
          )}
        </div>
        <div>
          {currentClient.miitelSetting?.isAvailable &&
            currentClient.miitelActive &&
            prospectPool.prospect.tel && (
              <MiitelIconWrapper
                onClick={() => miitelCall(prospectPool.prospect.tel)}>
                <Miitel />
              </MiitelIconWrapper>
            )}

          <Menu prospectPool={prospectPool} />
        </div>
      </ProspectNameWrapper>
      <Account prospectPool={prospectPool} />
      <div>{prospectPool.prospect.section}</div>
      <div>{prospectPool.prospect.sectionPosition}</div>
      {prospectPool.prospect.telephoneNumber && (
        <div className="flex">
          <a
            href={
              currentClient.zoomPhoneActive
                ? `zoomphonecall:${prospectPool.prospect.telephoneNumber}`
                : `tel:${prospectPool.prospect.telephoneNumber}`
            }>
            {prospectPool.prospect.telephoneNumber}
          </a>
          <Copy copyText={prospectPool.prospect.telephoneNumber} />
        </div>
      )}
      {prospectPool.prospect.email && (
        <div className="flex">
          {prospectPool.prospect.email}
          <Copy copyText={prospectPool.prospect.email} />
        </div>
      )}
      <div>
        <Tag prospectPool={prospectPool} />
      </div>
      <SearchLinkWrapper>
        <Search />
        <span>
          {account.corpNumber && (
            <SearchLink
              href={`https://www.google.com/search?q=${account.corpNumber}`}
              target="_blank">
              法人番号
            </SearchLink>
          )}
        </span>
        <span>
          {account.name && (
            <SearchLink
              href={`https://www.google.com/search?q=${account.name}`}
              target="_blank">
              会社名
            </SearchLink>
          )}
        </span>
        <span>
          {account.address && (
            <SearchLink
              href={`https://www.google.com/search?q=${account.address}`}
              target="_blank">
              住所
            </SearchLink>
          )}
        </span>
        <span>
          {account.representativePerson && (
            <SearchLink
              href={`https://www.google.com/search?q=${account.representativePerson}`}
              target="_blank">
              代表者
            </SearchLink>
          )}
        </span>
        <span>
          {prospectPool.prospect.lastName && account.name && (
            <SearchLink
              href={`https://www.google.com/search?q=${
                prospectPool.prospect.lastName +
                prospectPool.prospect.firstName +
                ' ' +
                account.name
              }`}
              target="_blank">
              名前 + 会社名
            </SearchLink>
          )}
        </span>
      </SearchLinkWrapper>
      <UtmParameter prospectPool={prospectPool} />
      <SalesForce prospectPool={prospectPool} />
    </Container>
  );
};
const MiitelIconWrapper = styled.div`
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > svg {
    width: 18px;
    height: 18px;
  }
`;

const Container = styled.div`
  > div {
    color: var(--text-color-2);
  }

  .flex {
    display: flex;

    div {
      margin-right: 8px;
    }

    svg {
      vertical-align: text-bottom;
    }
  }

  .ant-btn {
    display: flex;
    border: none;
    margin: 0;
    padding: 0;
    align-items: center;
  }

  .copy {
    margin-left: 8px;
    cursor: pointer;
  }
`;

const ProspectNameWrapper = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

const ProspectName = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: var(--text-color-0);
`;

const Unsubscribed = styled.div`
  color: #eb5757;
  font-size: 12px;
  background: #ffeeed;
  border-radius: 2px;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  gap: 3px;

  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: #eb5757;
    }
  }
`;

const Bounced = styled.div`
  color: var(--text-color-2);
  font-size: 12px;
  background: #e1e6eb;
  border-radius: 2px;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  gap: 3px;

  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: var(--text-color-2);
    }
  }
`;

const SearchLinkWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 12px;
  }

  span {
    margin-left: 6px;
  }
`;

const SearchLink = styled.a`
  color: var(--text-color-2);
  font-size: 10px;
  text-decoration: underline;
`;
