import React from 'react';

interface Props {
  position: string;
}

export default ({position}: Props) => {
  if (!position) return null;
  return (
    <div className="text-xs text-c-light truncate leading-none mb-2">
      {position}
    </div>
  );
};
