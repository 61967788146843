import React from 'react';
import styled from 'styled-components';
import {useWebsitesQuery, Website} from 'api';
import PageType from './PageType';
import KeywordForm from './Keyword';

interface Props {
  setActivePageType: (pageType: string) => void;
  pageKeywordsRef: any;
  pageNegativeKeywordsRef: any;
}

const Content = ({
  setActivePageType,
  pageKeywordsRef,
  pageNegativeKeywordsRef,
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [pageTypePositions, setPageTypePositions] = React.useState([]);
  const {data: {websites = []} = {}, loading} = useWebsitesQuery({
    variables: {
      search: {
        pageTypes: [
          'recruit',
          'recruitnew',
          'parttime',
          'press',
          'service',
          'welfare',
        ],
      },
    },
    onCompleted: () => {
      const sectionPositions = Array.prototype.slice
        .call(ref.current?.getElementsByTagName('section'))
        .map((section: any) => ({
          id: section.id,
          top: section.offsetTop - ref.current?.offsetTop,
          bottom:
            section.offsetTop - ref.current?.offsetTop + section.clientHeight,
          height: section.clientHeight,
        }));
      setPageTypePositions(sectionPositions);
    },
  });

  const websitesFiltered = websites.filter(
    (website) =>
      ![
        'hellowork',
        'mynavi2021',
        'mynavi2022',
        'mynavi2026',
        'rikunabi2021',
        'rikunabi2022',
        'rikunabi2026',
      ].includes(website.websiteType),
  );

  const handleScroll = () => {
    const activePageType = pageTypePositions.find((position: any) => {
      const scroll = ref.current?.scrollTop;
      return position.top <= scroll && position.bottom > scroll;
    });
    activePageType && setActivePageType(activePageType.id);
  };

  const websiteTypes = React.useMemo(() => {
    const types: any = {
      recruit: [],
      recruitnew: [],
      parttime: [],
      press: [],
      service: [],
      welfare: [],
    };

    websitesFiltered.forEach((website: Website) => {
      types[website.pageType] = [...types[website.pageType], website];
    });

    return types;
  }, [websitesFiltered]);

  return loading ? null : (
    <Container>
      <KeywordForm
        pageKeywordsRef={pageKeywordsRef}
        pageNegativeKeywordsRef={pageNegativeKeywordsRef}
      />
      <Websites
        onScroll={handleScroll}
        ref={ref}
        style={
          pageTypePositions.length > 0
            ? {
                paddingBottom: `calc(100vh - 140px - 80px - ${
                  pageTypePositions[pageTypePositions.length - 1].height
                }px)`,
              }
            : {}
        }>
        <PageType
          pageType="recruit"
          title="求人（中途）"
          websites={websiteTypes.recruit}
        />
        <PageType
          pageType="recruitnew"
          title="求人（新卒）"
          websites={websiteTypes.recruitnew}
        />
        <PageType
          pageType="parttime"
          title="求人（アルバイト）"
          websites={websiteTypes.parttime}
        />
        <PageType
          pageType="press"
          title="プレスリリース"
          websites={websiteTypes.press}
        />
        <PageType
          pageType="service"
          title="サービス・プロダクト"
          websites={websiteTypes.service}
        />
        <PageType
          pageType="welfare"
          title="介護・福祉"
          websites={websiteTypes.welfare}
        />
      </Websites>
    </Container>
  );
};
export {Content};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 400px;
  border-right: 1px solid #f3f3f5;
`;

const Websites = styled.div`
  padding-left: 30px;
  overflow-y: scroll;
  flex: 1;
`;
