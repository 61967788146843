import React from 'react';
import styled from 'styled-components';
import {ProjectMailStatusSummary} from 'api';
import 'chart.js/auto';
import {Doughnut} from 'react-chartjs-2';
import {StatusEclipse} from 'components/Ui';

interface Props {
  projectMailStatusSummary: ProjectMailStatusSummary;
}

export default React.memo(({projectMailStatusSummary}: Props) => {
  const data = React.useMemo(
    () => [
      projectMailStatusSummary?.mailCount || 0,
      projectMailStatusSummary?.errorCount || 0,
      projectMailStatusSummary?.unsentCount || 0,
    ],
    [projectMailStatusSummary],
  );

  const config = React.useMemo(
    () => ({
      type: 'doughnut',
      data: {
        labels: ['送信済み', '送信不可', '未送信'],
        datasets: [
          {
            data,
            backgroundColor: ['#8966EF', '#666666', '#b3b3b3'],
          },
        ],
      },
      options: {
        cutout: '55%',
        plugins: {
          legend: {
            position: 'bottom',
            title: {
              display: true,
              text: '',
            },
            display: false,
          },
        },
      },
    }),
    [data],
  );

  return (
    <>
      <Doughnut {...config} />
      <Legend>
        <div>
          <Name color={'#8966EF'}>
            <StatusEclipse color={'#8966EF'} />
            送信済み
          </Name>
          <Count>：{data[0].toLocaleString()}件</Count>
        </div>
        <div>
          <Name color={'var(--text-color-2)'}>
            <StatusEclipse color={'var(--text-color-2)'} />
            送信不可
          </Name>
          <Count>：{data[1].toLocaleString()}件</Count>
        </div>
        <div>
          <Name color={'var(--text-color-3)'}>
            <StatusEclipse color={'var(--text-color-3)'} />
            未送信
          </Name>
          <Count>：{data[2].toLocaleString()}件</Count>
        </div>
      </Legend>
    </>
  );
});

const Legend = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 22px 0 25px 0;
`;

const Name = styled.span<{color: string}>`
  color: ${({color}) => color};
  font-weight: bold;

  svg {
    margin-right: 3px;
  }
`;

const Count = styled.span`
  font-weight: bold;
`;
