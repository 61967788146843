import React from 'react';
import moment from 'moment';
import {useForm, FormProvider} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {Dialog, Transition} from '@headlessui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  useCreateClientDownloadProspectMutation,
  useProspectPoolsQuery,
  usePoolProspectCustomizeItemsQuery,
} from 'api';
import useSearchParams from '../useSearchParams';
import * as Yup from 'yup';
import CheckboxSection from './CheckboxSection';

const companyOptions = [
  {value: 'company_name', label: '会社名'},
  {value: 'company_web_site', label: 'URL'},
  {value: 'company_address', label: '住所'},
  {value: 'company_telephone_number', label: '代表電話'},
  {value: 'company_mail_address', label: '代表メール'},
  {value: 'company_corp_number', label: '法人番号'},
  {value: 'company_capital_fund', label: '資本金'},
  {value: 'company_employee_number', label: '従業員数'},
  {value: 'company_main_category', label: '業種メイン'},
  {value: 'company_sub_category', label: '業種サブ'},
  {value: 'has_company', label: '名寄せ'},
];

const defaultFields = [
  'id',
  'last_name',
  'first_name',
  'email',
  'company_name',
];

const sortOptions = (
  options: {value: string; label: string}[],
  values: string[],
) => {
  const optionValues = options.map((option) => option.value);
  const filteredValues = values.filter((value) => optionValues.includes(value));
  const sorted = filteredValues.sort((a, b) => {
    return optionValues.indexOf(a) - optionValues.indexOf(b);
  });
  return sorted;
};

interface Props {
  visible: boolean;
  onClose: () => void;
}

const validationSchema = Yup.object().shape({
  filename: Yup.string().required('必須項目です'),
  fields: Yup.array().of(Yup.string()).min(1, '必須項目です'),
});

const DownloadModal = ({visible, onClose}: Props) => {
  const [completed, setCompleted] = React.useState(false);

  const leadOptions = [
    {value: 'id', label: 'リードID'},
    {value: 'last_name', label: '姓'},
    {value: 'first_name', label: '名'},
    {value: 'original_lead_source', label: '初回リードソース'},
    {value: 'lead_source', label: '最新リードソース'},
    {value: 'original_inflow_date', label: '初回流入日'},
    {value: 'latest_inflow_date', label: '最新流入日'},
    {value: 'stage', label: 'ステージ'},
    {
      value: 'client_phase_prospect',
      label: 'フェーズ',
    },
    {value: 'email', label: 'メールアドレス'},
    {value: 'telephone_number', label: '電話番号'},
    {value: 'section', label: '部署'},
    {value: 'section_position', label: '役職'},
    {value: 'tags', label: 'タグ'},
    {value: 'user', label: 'リード担当者'},
    {value: 'is_unsubscribed', label: '配信停止'},
    {value: 'tel_updated_date', label: '最新コール日'},
    {value: 'last_tel_user_name', label: '最新コール担当者'},
    {
      value: 'last_client_prospect_tel_status_name',
      label: '最新コールステータス',
    },
    {value: 'utm_source', label: 'utm_source'},
    {value: 'utm_medium', label: 'utm_medium'},
    {value: 'utm_campaign', label: 'utm_campaign'},
    {value: 'utm_term', label: 'utm_term'},
    {value: 'utm_content', label: 'utm_content'},
  ];

  const {poolId} = useParams<{
    poolId: string;
  }>();

  const {page, perPage, searchParams} = useSearchParams();
  const {data: {prospectPools: {pagination = {}} = {}} = {}} =
    useProspectPoolsQuery({
      variables: {
        uuid: poolId,
        page: page,
        perPage: perPage,
        search: searchParams,
      },
      fetchPolicy: 'cache-and-network',
      skip: !poolId,
    });

  const [createProspectDownload] = useCreateClientDownloadProspectMutation({
    onCompleted: () => setCompleted(true),
  });

  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({
      variables: {poolId: poolId},
      fetchPolicy: 'cache-and-network',
      skip: !poolId,
    });

  const customizeItemOptions = React.useMemo(() => {
    return poolProspectCustomizeItems.map((item) => ({
      label: item.name,
      value: `custom_${item.id}`,
    }));
  }, [poolProspectCustomizeItems]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      filename: `リード一覧-${moment().format('YYYYMMDD')}`,
      fields: defaultFields,
    },
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = methods;

  const onSubmit = (data: {filename: string; fields: string[]}) => {
    createProspectDownload({
      variables: {
        poolUuid: poolId,
        attributes: {
          search: searchParams,
          filename: data.filename + '.csv',
          fields: [
            ...sortOptions(leadOptions, data.fields),
            ...sortOptions(companyOptions, data.fields),
            ...sortOptions(customizeItemOptions, data.fields),
          ],
        },
      },
    });
  };

  React.useEffect(() => {
    reset({
      filename: `リード一覧-${moment().format('YYYYMMDD')}`,
      fields: defaultFields,
    });
  }, [visible]);

  return (
    <Transition appear show={visible} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {completed ? (
                  <div className="flex flex-col items-center my-8 gap-8 text-base">
                    <h2 className="text-lg font-bold">
                      ダウンロードファイルを作成しています
                    </h2>
                    <ul className="list-disc">
                      <li>
                        処理完了後、ファイルをダウンロードいただけます（完了後、メールで通知します）
                      </li>
                      <li>
                        件数が多い場合、完了までに時間がかかる場合があります
                      </li>
                    </ul>
                    <a
                      href="/downloads"
                      className="bg-c-primary text-white leading-10 h-10 px-6 rounded">
                      ダウンロード履歴を確認
                    </a>
                  </div>
                ) : (
                  <>
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-bold">
                      CSVダウンロード - リード情報
                    </Dialog.Title>
                    <FormProvider {...methods}>
                      <form
                        className="flex flex-col gap-4 my-4"
                        onSubmit={handleSubmit(onSubmit)}>
                        <div>
                          <div className="text-base inline-block bg-c-bg items-center p-4 rounded">
                            対象:{' '}
                            {(pagination?.totalCount || 0).toLocaleString()}件
                          </div>
                        </div>
                        <ul className="text-sm list-disc list-inside">
                          <li>
                            処理完了後、[ダウンロード履歴]
                            からファイルをダウンロードいただけます（完了後、メールで通知します）
                          </li>
                          <li>
                            件数が多い場合、完了までに時間がかかる場合があります
                          </li>
                        </ul>
                        <div className="flex flex-col gap-6">
                          <div className="flex flex-col gap-2">
                            <label className="text-base font-bold m-0">
                              ファイル名を入力
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="flex items-center gap-2">
                              <input
                                type="text"
                                className="form-input text-base block w-[240px] border border-c-border rounded placeholder-c-lighter"
                                {...register('filename')}
                              />
                              <span className="text-lg">.csv</span>
                            </div>
                            {errors.filename && (
                              <div className="text-red-500">
                                {errors.filename.message}
                              </div>
                            )}
                          </div>
                          <label className="text-base font-bold m-0">
                            ダウンロード項目を選択
                          </label>
                          {errors.fields && (
                            <div className="text-red-500">
                              {errors.fields.message}
                            </div>
                          )}
                          <CheckboxSection
                            title="リード情報"
                            options={leadOptions}
                          />
                          <CheckboxSection
                            title="会社情報"
                            options={companyOptions}
                          />
                          <CheckboxSection
                            title="カスタム項目"
                            options={customizeItemOptions}
                          />
                          <div className="flex flex-row-reverse">
                            <button
                              type="submit"
                              className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-32 disabled:bg-c-bg disabled:text-c-light">
                              ダウンロード
                            </button>
                          </div>
                        </div>
                      </form>
                    </FormProvider>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DownloadModal;
