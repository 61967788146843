import React from 'react';
import {MailCampaignBlock, MailCampaignBlockButton} from 'api';

interface Block extends MailCampaignBlock {
  blockable: MailCampaignBlockButton;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const {text} = block.blockable;

  const style = {
    backgroundColor: block.blockable?.bodyColor,
    paddingTop: block.blockable?.paddingTop || 0,
    paddingBottom: block.blockable?.paddingBottom || 0,
    paddingLeft: block.blockable?.paddingLeft || 0,
    paddingRight: block.blockable?.paddingRight || 0,
    lineHeight: block.blockable?.lineHeight || 1.5,
    display: 'flex',
    justifyContent: 'center',
  };

  const buttonStyle = {
    backgroundColor: block.blockable?.buttonColor,
    color: block.blockable?.textColor,
    padding: '10px 20px',
    border: 'none',
    borderRadius: '8px',
    width: '100%',
  };

  return (
    <div style={style}>
      <button style={buttonStyle}>{text}</button>
    </div>
  );
};
