import React from 'react';

interface Props {
  telephoneNumber: string;
}

export default ({telephoneNumber}: Props) => {
  if (!telephoneNumber) return null;
  return (
    <div className="pb-2">
      <div className="px-2 bg-c-bg text-[#666666]">電話番号</div>
      <div className="pt-1">{telephoneNumber}</div>
    </div>
  );
};
