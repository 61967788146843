import React from 'react';
import {Menu, Transition} from '@headlessui/react';
import useFilterModalContext from '../useFilterModalContext';
import useCondition from '../../useCondition';
import {
  LeadSource,
  OriginalLeadSource,
  InflowDate,
  Stage,
  Tag,
  ExcludedTag,
  CustomizeItem,
  MailAddress,
  MonthsSinceAction,
  User,
  TrackedAt,
  ClientPhaseProspect,
} from './Condition';

type Option = {
  label: string;
  value: string;
  isUnselected: boolean;
};

const LeadFilters = () => {
  const {setFilterType} = useFilterModalContext();
  const {condition} = useCondition();

  const options: Option[] = [
    {
      label: '最新リードソース',
      value: 'leadSource',
      isUnselected: !condition?.leadSources || !condition?.leadSources.length,
    },
    {
      label: '初回リードソース',
      value: 'originalLeadSource',
      isUnselected:
        !condition?.originalLeadSources ||
        !condition?.originalLeadSources.length,
    },
    {
      label: '流入日',
      value: 'inflowDate',
      isUnselected: !condition?.inflowDateFrom && !condition?.inflowDateTo,
    },
    {
      label: '担当者',
      value: 'user',
      isUnselected: !condition?.users || !condition?.users.length,
    },
    {
      label: 'フェーズ',
      value: 'clientPhaseProspect',
      isUnselected:
        !condition?.clientPhaseProspects ||
        !condition?.clientPhaseProspects.length,
    },
    {
      label: 'ステージ',
      value: 'stage',
      isUnselected: !condition?.stages || !condition.stages.length,
    },
    {
      label: 'タグ',
      value: 'tag',
      isUnselected: !condition?.tags || !condition.tags.length,
    },
    {
      label: 'タグ（含まない）',
      value: 'excludedTag',
      isUnselected: !condition?.excludedTags || !condition.excludedTags.length,
    },
    {
      label: 'カスタム項目',
      value: 'customizeItem',
      isUnselected:
        !condition?.prospectCustomizeItemSearchConditions ||
        !condition.prospectCustomizeItemSearchConditions.length,
    },
    {
      label: 'メールアドレス',
      value: 'mailAddress',
      isUnselected:
        condition?.isEmail === null || condition?.isEmail === undefined,
    },
    {
      label: 'アクション経過',
      value: 'monthsSinceAction',
      isUnselected:
        condition?.monthsSinceAction === null ||
        condition?.monthsSinceAction === undefined,
    },
    {
      label: '最新Webサイト訪問期間',
      value: 'trackedAt',
      isUnselected: !condition?.trackedAtFrom && !condition?.trackedAtTo,
    },
  ];

  const unselectedOptions = options.filter((option) => option.isUnselected);

  return (
    <div className="p-6 bg-white rounded flex items-start gap-4">
      <h3 className="m-0 font-bold text-base w-20">リード</h3>
      <div className="flex-1 flex flex-col gap-4">
        <LeadSource />
        <OriginalLeadSource />
        <InflowDate />
        <User />
        <ClientPhaseProspect />
        <Stage />
        <Tag />
        <ExcludedTag />
        <CustomizeItem />
        <MailAddress />
        <MonthsSinceAction />
        <TrackedAt />
        <Menu as="div" className="relative text-left inline-block">
          <Menu.Button className="cursor-pointer bg-white border border-c-border rounded text-sm flex items-center justify-center h-7 px-4">
            + 検索条件を追加
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute left-0 border border-c-border w-[10rem] rounded bg-white shadow-lg focus:outline-none z-10">
              <div className="px-1 py-1 ">
                {unselectedOptions.map((option, index) => (
                  <Menu.Item key={index}>
                    <button
                      onClick={() => setFilterType(option.value)}
                      className="cursor-pointer bg-white text-left p-2 w-full hover:bg-[#F1F8FF] hover:text-c-primary">
                      {option.label}
                    </button>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default LeadFilters;
