import React from 'react';
import useSearchParams from '../../../../useSearchParams';
import Header from '../../Header';
import Footer from '../Footer';
import CustomizeItemField from './CustomizeItemField';
import CustomizeItemSelectOption from './CustomizeItemSelectOption';
import CustomizeItemDate from './CustomizeItemDate';
import Close from './Close';
import {PlusIcon} from './Icon';

const CustomizeItemFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="カスタム項目" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="flex flex-col gap-5">
          {query?.prospectCustomizeItemSearchConditions?.map(
            (customizeItem: any, index: number) => {
              return (
                <div key={index} className="flex gap-3">
                  <CustomizeItemField
                    customizeItemId={customizeItem['customize_item_id']}
                    index={index}
                  />
                  {customizeItem['data_type'] === 'select' && (
                    <CustomizeItemSelectOption
                      customizeItemId={customizeItem['customize_item_id']}
                      customizeItemValue={customizeItem['values']}
                      index={index}
                    />
                  )}
                  {customizeItem['data_type'] === 'date' && (
                    <CustomizeItemDate
                      customizeItemId={customizeItem['customize_item_id']}
                      from={customizeItem['from']}
                      to={customizeItem['to']}
                      index={index}
                    />
                  )}
                  <Close index={index} />
                </div>
              );
            },
          )}
        </div>
        <button
          className="h-7 w-28 text-center rounded bg-white border border-border flex justify-center items-center"
          onClick={() => {
            setQuery({
              ...query,
              prospectCustomizeItemSearchConditions: [
                ...query.prospectCustomizeItemSearchConditions,
                {},
              ],
            });
          }}>
          <PlusIcon /> <span className="text-foreground-light">条件を追加</span>
        </button>
      </div>
      <Footer
        onClear={() =>
          setQuery({...query, prospectCustomizeItemSearchConditions: []})
        }
      />
    </>
  );
};

export default CustomizeItemFilter;
