import {KeyPerson} from 'api';
import React from 'react';
import Detail from './Detail';

interface Props {
  keyPerson: KeyPerson;
  preleadUuid: string;
}

const KeyPersonComponent = ({keyPerson, preleadUuid}: Props) => {
  const preleadKeyPerson = keyPerson.preleadKeyPerson;
  const params = preleadKeyPerson
    ? {
        id: keyPerson.id,
        keyPerson: keyPerson,
        fullName: [preleadKeyPerson.lastName, preleadKeyPerson.firstName]
          .filter(Boolean)
          .join(' '),
        department: preleadKeyPerson.department,
        position: preleadKeyPerson.position,
        facebookUrl: preleadKeyPerson.facebookUrl,
        linkedinUrl: preleadKeyPerson.linkedinUrl,
        xUrl: preleadKeyPerson.xUrl,
        wantedlyUrl: preleadKeyPerson.wantedlyUrl,
        eightUrl: preleadKeyPerson.eightUrl,
        telephoneNumber: preleadKeyPerson.telephoneNumber,
        note: preleadKeyPerson.note,
      }
    : {
        id: keyPerson.id,
        keyPerson: keyPerson,
        fullName: [keyPerson.lastName, keyPerson.firstName]
          .filter(Boolean)
          .join(' '),
        department: keyPerson.department,
        position: keyPerson.position,
        facebookUrl: keyPerson.facebookUrl,
        linkedinUrl: keyPerson.linkedinUrl,
        xUrl: keyPerson.xUrl,
        wantedlyUrl: keyPerson.wantedlyUrl,
        eightUrl: keyPerson.eightUrl,
        telephoneNumber: keyPerson.telephoneNumber,
        note: null,
      };

  return <Detail params={params} preleadUuid={preleadUuid} />;
};

export default KeyPersonComponent;
