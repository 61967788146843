import React from 'react';
import CreateNewItem from './CreateNewItem';
import List from './List';

export default () => {
  return (
    <div className="flex flex-col gap-4">
      <CreateNewItem />
      <List />
    </div>
  );
};
