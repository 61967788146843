import React from 'react';

interface Props {
  fullName: string;
}

export default ({fullName}: Props) => {
  if (!fullName) return null;
  return (
    <span className="mr-6 mb-2 font-bold text-c-base text-[16px]">
      {fullName}
    </span>
  );
};
