import React from 'react';
import styled from 'styled-components';
import {
  ProjectUserTelStatus,
  useAllProjectsTelStatusCallWeeklyHistoryReportsQuery,
} from 'api';
import useSearchParams from '../useSearchParams';
import moment from 'moment';
import {StatusEclipse} from 'components/Ui';
import telCategoryColor from 'helpers/telCategoryColor';
import _ from 'lodash';
import {Button} from 'components/antd';
import {ArrowRight, Download} from 'components/Ui/Icon';
import {telStatusCategoryHash} from 'helpers/telStatusCategoryHash';
import DownloadModal from './CallReportDownloadModal';
import 'chart.js/auto';
import {Bar} from 'react-chartjs-2';

export default () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const {query, searchParams, setQuery} = useSearchParams();
  const {data: {allProjectsTelStatusCallWeeklyHistoryReports = []} = {}} =
    useAllProjectsTelStatusCallWeeklyHistoryReportsQuery({
      variables: {
        search: searchParams,
      },
      fetchPolicy: 'network-only',
    });

  const totalCount = (object: any): any => {
    const value = Object.values(object).reduce(
      (total: number, value: number) => total + value,
      0,
    );
    if (!value) return null;

    return value;
  };

  const totalCountOfAllCategories = React.useCallback(
    (report: ProjectUserTelStatus) => {
      let count = 0;

      count += totalCount(report.call);
      count += totalCount(report.reception);
      count += totalCount(report.contact);
      count += totalCount(report.appoint);
      count += totalCount(report.document);
      count += totalCount(report.other);
      count += totalCount(report.callNg);
      count += totalCount(report.receptionNg);
      count += totalCount(report.contactNg);

      return count;
    },
    [],
  );

  if (allProjectsTelStatusCallWeeklyHistoryReports.length === 0) return null;

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        reverse: true,
        labels: {
          usePointStyle: true,
        },
      },
    },
  };

  const day = () => {
    let list: string[] = [];
    allProjectsTelStatusCallWeeklyHistoryReports.map((report: any) => {
      list.push(
        `${
          moment(report.countDate).startOf('isoWeek').format('MM/DD') +
          ' - ' +
          moment(report.countDate).endOf('isoWeek').format('MM/DD')
        }`,
      );
    });
    return list;
  };

  const mergeDataIntoCategory = (categories: any, report: any) => {
    let catMap: any = Object.entries(categories).reduce(
      (catMap, [key, label]) => ({
        ...catMap,
        [key]: {
          label,
          backgroundColor: [],
          data: [],
          stack: 1,
        },
      }),
      {},
    );
    report.forEach((el: any) =>
      Object.entries(el).forEach(([key, value]) => {
        catMap[_.snakeCase(key)] &&
          catMap[_.snakeCase(key)].data.push(totalCount(value));
        catMap[_.snakeCase(key)] &&
          catMap[_.snakeCase(key)].backgroundColor.push(
            telCategoryColor(_.snakeCase(key)),
          );
      }),
    );

    return Object.values(catMap);
  };

  const res = mergeDataIntoCategory(
    telStatusCategoryHash,
    allProjectsTelStatusCallWeeklyHistoryReports,
  );

  return (
    <Container>
      <div>
        <div>
          <Display>
            <span>
              {query.date
                ? moment(query.date).format('YYYY年MM月')
                : moment().format('YYYY年MM月')}
            </span>
            <Button
              type="text"
              className="left"
              onClick={() =>
                setQuery({
                  date: moment(query.date)
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD'),
                })
              }>
              <ArrowRight />
            </Button>
            <Button
              type="text"
              onClick={() =>
                setQuery({
                  date: moment(query.date)
                    .add(1, 'months')
                    .format('YYYY-MM-DD'),
                })
              }>
              <ArrowRight />
            </Button>

            <Button
              className="today"
              onClick={() => setQuery({date: moment().format('YYYY-MM-DD')})}>
              今月
            </Button>
          </Display>

          <DownloadButton onClick={() => setModalOpen(true)}>
            <Download />
            ダウンロード
          </DownloadButton>
        </div>
      </div>
      <Table>
        <div className="column far-left">
          <div className="far-top">
            <span></span>
          </div>

          <div className="category all">
            <span>コール件数（累積）</span>
          </div>

          {Object.keys(telStatusCategoryHash).map((category) => (
            <React.Fragment key={category}>
              <div className={'category'}>
                <StatusEclipse color={telCategoryColor(category)} />
                <span>{telStatusCategoryHash[category]}</span>
              </div>
            </React.Fragment>
          ))}
        </div>

        {allProjectsTelStatusCallWeeklyHistoryReports.map((report: any) => (
          <div className="column" key={report.countDate}>
            <div
              className={
                'far-top ' +
                (moment(report.countDate).isSame(new Date(), 'isoWeek') &&
                  'this-week')
              }>
              <span>W{moment(report.countDate).isoWeek()}</span>
              <span>
                {moment(report.countDate).startOf('isoWeek').format('MM/DD')} -{' '}
                {moment(report.countDate).endOf('isoWeek').format('MM/DD')}
              </span>
            </div>

            <div className="category all">
              <span>
                {totalCountOfAllCategories(report) === 0
                  ? null
                  : totalCountOfAllCategories(report)}
              </span>
            </div>

            {Object.keys(telStatusCategoryHash).map((category) => (
              <React.Fragment key={category}>
                <div className="category">
                  <span>
                    {report[_.camelCase(category)] &&
                      totalCount(report[_.camelCase(category)])}
                  </span>
                </div>
              </React.Fragment>
            ))}
          </div>
        ))}
      </Table>
      <div style={{marginTop: '64px'}}>
        <Bar
          data={{
            labels: day(),
            datasets: res.reverse(),
          }}
          width={100}
          height={50}
          options={options}
        />
      </div>

      {isModalOpen && (
        <DownloadModal isOpen={isModalOpen} setModal={setModalOpen} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    > h2 {
      margin: auto auto auto 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
      color: var(--text-color-1);
    }

    > div {
      position: relative;
      margin: auto 0 auto auto;
      display: flex;
    }
  }
`;

const Display = styled.div`
  margin-left: 10px;
  margin-top: 10px;

  > span {
    font-size: 12px;
    line-height: 17px;
    color: var(--text-color-1);
  }

  > button {
    padding: 5.6px 6px;

    svg {
      path {
        stroke: var(--text-color-2);
      }
    }

    &.left {
      svg {
        transform: rotate(180deg);
      }
    }

    &.today {
      margin-left: 10px;
      padding: 5.6px 10px;
      border: 1px solid #e1e6eb;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
`;

const DownloadButton = styled(Button)`
  position: absolute;
  top: -32px;
  right: 0px;

  display: flex;
  border: 1px solid #e1e6eb;
  border-radius: 4px;

  > svg {
    margin-right: 6px;
  }

  > span {
    margin: auto 0;
    font-weight: bold;
    font-size: 12px;
    color: var(--text-color-1);
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  overflow: scroll;
  min-height: 300px;
  max-height: 600px;
  border-right: none;
  border-bottom: none;

  .column {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e1e6eb;

    > div {
      width: 160px;
      min-height: 43px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;

      &.all {
        border-bottom: 1px solid #e1e6eb;
        font-weight: bold;
      }

      display: flex;
      align-items: center;

      &:first-child {
        > span {
          margin: 0 auto;
        }

        &.far-top {
          top: 0;
          z-index: 3;
          position: sticky;
          background: #ffffff;
          // border-bottom: 1px solid #e1e6eb;
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            margin: auto;
          }

          &.this-week {
            border-top: 2px solid var(--text-color-1);

            span {
              color: var(--text-color-1);
              font-weight: bold;
            }
          }
        }
      }

      &:nth-child(2) {
        border-top: none;
      }

      > span {
        margin: 0 9px 0 auto;
        color: var(--text-color-2);
      }

      &.category {
        > span {
          color: var(--text-color-1);
          // font-weight: bold;
        }
      }
    }

    &.far-left {
      left: 0;
      z-index: 10;
      position: sticky;
      background: #ffffff;

      > div {
        width: 200px;
        display: flex;
        align-items: center;
        padding: 0 13px 0 40px;

        background: #fff;

        &.category {
          padding: 0 13px;

          svg {
            margin-right: 5px;
          }
          span {
            font-weight: bold;
          }
        }

        &.openable {
          cursor: pointer;
        }

        > span {
          margin: 0;
          font-size: 13px;
        }
      }
    }
  }
`;
