import React from 'react';
import usePreleadContext from '../usePreleadContext';
import {useKeyPersonsByPreleadQuery} from 'api';

import KeyPersonComponent from 'components/KeyPerson';

export default () => {
  const {preleadId} = usePreleadContext();
  if (!preleadId) return null;

  const {data: {keyPersonsByPrelead = []} = {}} = useKeyPersonsByPreleadQuery({
    variables: {
      uuid: preleadId,
    },
  });

  return (
    <>
      {keyPersonsByPrelead.map((keyPerson) => (
        <KeyPersonComponent
          keyPerson={keyPerson}
          preleadUuid={preleadId}
          key={keyPerson.id}
        />
      ))}
    </>
  );
};
