import React from 'react';
import {
  SalesforceDealField,
  SalesforceDeal,
  useUpdateSalesforceDealFieldValueMutation,
} from 'api';

interface Props {
  field: SalesforceDealField;
  salesforceDeal: SalesforceDeal;
}

const Date = ({field, salesforceDeal}: Props) => {
  const value = salesforceDeal.addition[`${field.fieldName}`];
  const [erorrMessage, setErrorMessage] = React.useState('');
  const [updateFieldValue] = useUpdateSalesforceDealFieldValueMutation({
    onCompleted: (data) => {
      setErrorMessage(data.updateSalesforceDealFieldValue.error);
    },
  });

  return (
    <>
      <input
        defaultValue={value}
        className="w-max"
        type="date"
        onBlur={(e) => {
          e.target.value
            ? updateFieldValue({
                variables: {
                  salesforceDealId: salesforceDeal.id,
                  attributes: {
                    fieldName: field.fieldName,
                    value: e.target.value,
                  },
                },
              })
            : updateFieldValue({
                variables: {
                  salesforceDealId: salesforceDeal.id,
                  attributes: {
                    fieldName: field.fieldName,
                  },
                },
              });
        }}
      />
      {erorrMessage && <p className="text-red-500">{erorrMessage}</p>}
    </>
  );
};

export default Date;
