import {useKeyPersonsByPreleadQuery, usePreleadProjectQuery} from 'api';
import React from 'react';
import {useParams} from 'react-router-dom';
import KeyPersonComponent from 'components/KeyPerson';

export default () => {
  const {preleadProjectId} = useParams<{preleadProjectId: string}>();
  if (!preleadProjectId) return null;

  const {data: {preleadProject: {prelead = {}} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
      fetchPolicy: 'cache-and-network',
    });
  const {data: {keyPersonsByPrelead = []} = {}} = useKeyPersonsByPreleadQuery({
    variables: {
      uuid: prelead.uuid,
    },
  });

  return (
    <>
      {keyPersonsByPrelead.map((keyPerson) => (
        <KeyPersonComponent
          keyPerson={keyPerson}
          preleadUuid={prelead.uuid}
          key={keyPerson.id}
        />
      ))}
    </>
  );
};
