import React from 'react';
import SelectItem from './Select';
import {PoolProspectCustomizeItem} from 'api';

interface Props {
  customizeItem: PoolProspectCustomizeItem;
}

const Item = ({customizeItem}: Props) => {
  if (customizeItem?.dataType !== 'select') {
    return <></>;
  }
  return <SelectItem customizeItem={customizeItem} />;
};

export default Item;
