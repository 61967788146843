import React from 'react';
import {AccountPool} from 'api';
import {Row} from 'react-table';
import {scoreColor, scoreColorForeground} from 'helpers/scoreColors';

interface Props {
  row: Row<AccountPool>;
}

const ScoreCell = ({row}: Props) => {
  const accountPool: AccountPool = row.original;
  const score = accountPool.score?.scoreRank;
  const bgColor = scoreColor(score);
  const fgColor = scoreColorForeground(score);

  return (
    <div className="flex items-center justify-center w-full h-full">
      <span
        className="flex items-center justify-center w-6 h-6 rounded border font-bold"
        style={{
          backgroundColor: bgColor,
          color: fgColor,
          borderColor: fgColor,
        }}>
        {score}
      </span>
    </div>
  );
};

export default ScoreCell;
