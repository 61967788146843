import {KeyPerson} from 'api';
import React from 'react';
import Detail from './Detail';

interface Props {
  keyPerson: KeyPerson;
  accountPoolUuid: string;
}

const KeyPersonComponent = ({keyPerson, accountPoolUuid}: Props) => {
  const accountKeyPerson = keyPerson.accountKeyPerson;
  const params = accountKeyPerson
    ? {
        id: keyPerson.id,
        keyPerson: keyPerson,
        fullName: [accountKeyPerson.lastName, accountKeyPerson.firstName]
          .filter(Boolean)
          .join(' '),
        department: accountKeyPerson.department,
        position: accountKeyPerson.position,
        facebookUrl: accountKeyPerson.facebookUrl,
        linkedinUrl: accountKeyPerson.linkedinUrl,
        xUrl: accountKeyPerson.xUrl,
        wantedlyUrl: accountKeyPerson.wantedlyUrl,
        eightUrl: accountKeyPerson.eightUrl,
        telephoneNumber: accountKeyPerson.telephoneNumber,
        note: accountKeyPerson.note,
      }
    : {
        id: keyPerson.id,
        keyPerson: keyPerson,
        fullName: [keyPerson.lastName, keyPerson.firstName]
          .filter(Boolean)
          .join(' '),
        department: keyPerson.department,
        position: keyPerson.position,
        facebookUrl: keyPerson.facebookUrl,
        linkedinUrl: keyPerson.linkedinUrl,
        xUrl: keyPerson.xUrl,
        wantedlyUrl: keyPerson.wantedlyUrl,
        eightUrl: keyPerson.eightUrl,
        telephoneNumber: keyPerson.telephoneNumber,
        note: null,
      };

  return <Detail params={params} accountPoolUuid={accountPoolUuid} />;
};

export default KeyPersonComponent;
