import React from 'react';
import Prospects from './Prospects';
import Information from './Information';
import RightContent from './RightContent';
import Signal from './Signal';

export default () => {
  return (
    <div className="flex gap-6">
      <div className="flex-1 flex flex-col gap-4">
        <Prospects />
        <Information />
        <Signal />
      </div>
      <RightContent />
    </div>
  );
};
