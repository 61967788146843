import {ProspectPool} from 'api';
import {AlertImportant, ArrowDown} from 'components/Ui/Icon';
import React, {useState} from 'react';
import Parameter from './Parameter';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  if (!prospectPool) return null;
  const {utmSource, utmMedium, utmCampaign, utmTerm, utmContent} = prospectPool;
  if (!utmSource && !utmMedium && !utmCampaign && !utmTerm && !utmContent) {
    return null;
  }
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="w-fit flex items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}>
        <span className="h-6 mr-1">
          <AlertImportant />
        </span>
        <span>utmパラメータ</span>
        <button
          className={`bg-transparent h-6 w-auto transform ${isOpen ? 'rotate-180' : 'rotate-0'} cursor-pointer`}>
          <ArrowDown className="h-4 w-4" />
        </button>
      </div>
      {isOpen && (
        <div>
          <Parameter label="utm_source" parameter={prospectPool?.utmSource} />
          <Parameter label="utm_medium" parameter={prospectPool?.utmMedium} />
          <Parameter
            label="utm_campaign"
            parameter={prospectPool?.utmCampaign}
          />
          <Parameter label="utm_term" parameter={prospectPool?.utmTerm} />
          <Parameter label="utm_content" parameter={prospectPool?.utmContent} />
        </div>
      )}
    </>
  );
};
