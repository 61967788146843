import React, {useState} from 'react';
import {
  ProspectPool,
  useAccountScoreSettingQuery,
  useCurrentClientQuery,
  useCurrentUserQuery,
  useProspectScoreSettingQuery,
} from 'api';
import ScoreCircle from 'components/ProspectScore/ScoreCircle';
import {Dialog, Transition} from '@headlessui/react';
import ScoreItem from './ScoreItem';
import {ChevronDownIcon, XMarkIcon} from '@heroicons/react/24/outline';
import {scoreColor, scoreColorForeground} from 'helpers/scoreColors';

const Score = ({prospectPool}: {prospectPool: ProspectPool}) => {
  const prospectScore = prospectPool?.score;
  const accountScore = prospectPool?.accountPool.score;
  const account = prospectPool?.prospect?.account;

  const [isShowing, setIsShowing] = useState(false);

  const {data: {currentUser = null} = {}} = useCurrentUserQuery();
  const {data: {currentClient: {currentScoreContract = {}} = {}} = {}} =
    useCurrentClientQuery();
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery(
    {},
  );

  if (currentUser?.role !== 'admin' && !currentScoreContract?.isActive) {
    return null;
  }
  return (
    <>
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setIsShowing(true)}>
        <ScoreCircle
          score={prospectPool?.totalScore}
          rank={prospectPool?.scoreRank}
        />
        <button className="border border-border rounded bg-white w-5 h-5 flex items-center justify-center cursor-pointer">
          <ChevronDownIcon className="text-foreground-light w-2 h-2" />
        </button>
      </div>
      <Transition appear show={isShowing} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsShowing(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="relative transform rounded bg-white text-left align-middle shadow-xl transition-all overflow-hidden">
                  <div className="w-full h-full flex flex-col">
                    <div className="h-12 flex items-center px-6 gap-4 bg-white">
                      <h2 className="m-0 text-lg flex-1">顧客スコア内訳</h2>
                      <button
                        className="p-0 bg-transparent border-none cursor-pointer w-6 h-6"
                        onClick={() => setIsShowing(false)}>
                        <XMarkIcon className="h-6 w-6 text-c-lighter" />
                      </button>
                    </div>
                    <div className="p-6 bg-background flex">
                      <div className="bg-white p-6 flex flex-col gap-4 items-center">
                        <div className="flex items-center gap-2 font-bold text-xs">
                          <span className="text-foreground-light">企業</span>
                          <span
                            className="font-bold text-lg rounded-sm h-6 w-6 flex items-center justify-center"
                            style={{
                              backgroundColor: scoreColor(
                                accountScore?.scoreRank,
                              ),
                              color: scoreColorForeground(
                                accountScore?.scoreRank,
                              ),
                            }}>
                            {accountScore?.scoreRank}
                          </span>
                          <span className="font-bold text-lg">
                            {accountScore?.totalScore}
                          </span>
                        </div>
                        <div className="flex flex-col gap-x-8 gap-y-2 flex-wrap max-h-[480px]">
                          <ScoreItem
                            title="業種"
                            score={Math.round(
                              accountScore?.subCategoriesScore * 0.6,
                            )}
                            target={
                              !!accountScoreSetting?.weightSubCategory &&
                              account?.company?.subCategories?.length > 0
                            }
                            hasSetting={
                              accountScoreSetting?.subCategories?.length > 0
                            }
                            color="var(--score-blue-foreground)"
                          />
                          <ScoreItem
                            title="決算"
                            score={Math.round(
                              accountScore?.accountClosingMonthScore * 0.6,
                            )}
                            target={
                              !!accountScoreSetting?.weightAccountClosingMonth &&
                              !!account?.accountClosingMonth
                            }
                            hasSetting={
                              accountScoreSetting
                                ?.clientAccountScoreSettingAccountClosingMonths
                                ?.length > 0
                            }
                            color="var(--score-blue-foreground)"
                          />
                          <ScoreItem
                            title="従業員数"
                            score={accountScore?.employeeRangeScore}
                            target={
                              !!accountScoreSetting?.weightEmployeeRange &&
                              !!account?.employeeNumber
                            }
                            hasSetting={
                              accountScoreSetting?.employeeRanges?.length > 0
                            }
                            color="var(--score-blue-foreground)"
                          />
                          <ScoreItem
                            title="資本金"
                            score={accountScore?.capitalFundRangeScore}
                            target={
                              !!accountScoreSetting?.weightCapitalFundRange &&
                              !!account?.capitalFund
                            }
                            hasSetting={
                              accountScoreSetting?.capitalFundRanges?.length > 0
                            }
                            color="var(--score-blue-foreground)"
                          />
                          <ScoreItem
                            title="求人・メディア"
                            score={Math.round(accountScore?.websiteScore)}
                            target={account?.company?.websites?.length > 0}
                            hasSetting={
                              accountScoreSetting
                                ?.clientAccountScoreSettingWebsiteTypes
                                ?.length > 0
                            }
                            color="var(--score-blue-foreground)"
                          />
                        </div>
                      </div>
                      <div className="bg-white p-6 flex flex-col gap-4 items-center">
                        <div className="flex items-center gap-2 font-bold text-xs">
                          <span className="text-foreground-light">リード</span>
                          <span
                            className="font-bold text-lg rounded-sm h-6 w-6 flex items-center justify-center"
                            style={{
                              backgroundColor: scoreColor(
                                prospectScore?.scoreRank,
                              ),
                              color: scoreColorForeground(
                                prospectScore?.scoreRank,
                              ),
                            }}>
                            {prospectScore?.scoreRank}
                          </span>
                          <span className="font-bold text-lg">
                            {prospectScore?.totalScore}
                          </span>
                        </div>
                        <div className="flex flex-col gap-x-8 gap-y-2 flex-wrap max-h-[480px]">
                          <ScoreItem
                            title="役職"
                            score={Math.round(
                              prospectScore?.positionScore * 0.6,
                            )}
                            target={
                              !!prospectScoreSetting?.weightPosition &&
                              !!prospectPool.prospect.sectionPosition
                            }
                            hasSetting={
                              prospectScoreSetting
                                ?.clientProspectScoreSettingPositionKeywords
                                ?.length > 0
                            }
                          />
                          <ScoreItem
                            title="部署"
                            score={Math.round(
                              prospectScore?.sectionScore * 0.6,
                            )}
                            target={
                              !!prospectScoreSetting?.weightSection &&
                              !!prospectPool.prospect.section
                            }
                            hasSetting={
                              prospectScoreSetting
                                ?.clientProspectScoreSettingSectionKeywords
                                ?.length > 0
                            }
                          />
                          <ScoreItem
                            title="リードソース"
                            score={Math.round(
                              prospectScore?.leadSourceScore * 0.6,
                            )}
                            target={
                              !!prospectScoreSetting?.weightLeadSource &&
                              !!prospectPool.leadSource
                            }
                            hasSetting={
                              prospectScoreSetting?.leadSources?.length > 0
                            }
                          />
                          <ScoreItem
                            title="フェーズ"
                            score={Math.round(
                              prospectScore?.phaseProspectScore * 0.6,
                            )}
                            target={
                              !!prospectScoreSetting?.weightPhaseProspect &&
                              !!prospectPool.clientPhaseProspect
                            }
                            hasSetting={
                              prospectScoreSetting?.clientPhaseProspects
                                ?.length > 0
                            }
                          />
                          <ScoreItem
                            title="タグ"
                            score={Math.round(prospectScore?.tagScore * 0.6)}
                            target={
                              !!prospectScoreSetting?.weightProspectTag &&
                              !!prospectPool.prospectTags?.length
                            }
                            hasSetting={
                              prospectScoreSetting?.prospectTags?.length > 0
                            }
                          />
                          <ScoreItem
                            title="最新流入日"
                            score={Math.round(
                              prospectScore?.latestInflowDateScore * 0.6,
                            )}
                            target={
                              !!prospectPool.latestInflowDate &&
                              !!prospectScoreSetting?.weightLatestInflowDate
                            }
                            hasSetting={
                              prospectScoreSetting?.inflowDateRanges?.length > 0
                            }
                          />
                          <ScoreItem
                            title="失注時期"
                            score={Math.round(prospectScore?.lostAtScore * 0.6)}
                            target={
                              !!prospectScoreSetting?.weightLostAt &&
                              !!prospectPool.lostAt
                            }
                            hasSetting={
                              prospectScoreSetting?.lostAtDateRanges?.length > 0
                            }
                          />
                          <ScoreItem
                            title="Web訪問時期"
                            score={Math.round(
                              prospectScore?.trackedAtScore * 0.6,
                            )}
                            target={
                              !!prospectScoreSetting?.weightTrackedAt &&
                              !!prospectPool.trackedAt
                            }
                            hasSetting={
                              prospectScoreSetting?.trackedAtDateRanges
                                ?.length > 0
                            }
                          />
                          <ScoreItem
                            title="メールクリック時期"
                            score={Math.round(
                              prospectScore?.mailClickedAtScore * 0.6,
                            )}
                            target={
                              !!prospectScoreSetting?.weightMailClickedAt &&
                              !!prospectPool.mailClickedAt
                            }
                            hasSetting={
                              prospectScoreSetting?.mailClickedAtDateRanges
                                ?.length > 0
                            }
                          />
                          <ScoreItem
                            title="メール開封時期"
                            score={Math.round(
                              prospectScore?.mailOpenedAtScore * 0.6,
                            )}
                            target={
                              !!prospectScoreSetting?.weightMailOpenedAt &&
                              !!prospectPool.mailOpenedAt
                            }
                            hasSetting={
                              prospectScoreSetting?.mailOpenedAtDateRanges
                                ?.length > 0
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Score;
