import React from 'react';
import {XMarkIcon} from '@heroicons/react/24/outline';

interface Props {
  onClose: () => void;
}

const Header = ({onClose}: Props) => {
  return (
    <div className="h-12 flex items-center px-6 gap-4 bg-white">
      <h2 className="m-0 text-lg flex-1">フェーズ</h2>
      <div className="flex gap-1 flex-end">
        <span className="text-sm leading-6 mr-2">検索結果:</span>
        <span className="text-lg text-[#0085FF] font-bold leading-6">{0}</span>
        <span className="text-sm leading-6">件</span>
      </div>
      <button
        className="p-0 bg-transparent border-none cursor-pointer w-6 h-6"
        onClick={() => onClose()}>
        <XMarkIcon className="h-6 w-6 text-c-lighter" />
      </button>
    </div>
  );
};

export default Header;
