import gql from 'graphql-tag';

export default gql`
  fragment prospectScoreSettingFragment on ProspectScoreSetting {
    id
    clientProspectScoreSettingPositionKeywords {
      id
      name
    }
    clientProspectScoreSettingSectionKeywords {
      id
      name
    }
    leadSourceIds
    leadSources {
      id
      name
    }
    clientPhaseProspects {
      id
      name
    }
    prospectTagsGroupByPriority
    leadSourcesGroupByPriority
    clientProspectScoreSettingPhaseProspectsByPriority
    prospectTags {
      id
      name
    }
    clientProspectScoreSettingPositionKeywordsByPriority
    clientProspectScoreSettingSectionKeywordsByPriority
    updateStatus
    weightPosition
    weightLeadSource
    weightProspectTag
    weightLatestInflowDate
    weightSection
    weightMailClickedAt
    weightMailOpenedAt
    weightLostAt
    weightTrackedAt
    weightPhaseProspect
    inflowDateRanges {
      id
      priority
      dateRange
    }
    mailClickedAtDateRanges {
      id
      priority
      dateRange
    }
    mailOpenedAtDateRanges {
      id
      priority
      dateRange
    }
    lostAtDateRanges {
      id
      priority
      dateRange
    }
    trackedAtDateRanges {
      id
      priority
      dateRange
    }
    clientProspectScoreSettingCustomizeItems {
      id
      dataType
      poolProspectCustomizeItem {
        ...poolProspectCustomizeItemFragment
      }
    }
  }
`;
