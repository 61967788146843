import React from 'react';
import Header from '../Header';
import Footer from './Footer';
import useSearchParams from '../../../useSearchParams';
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline';

const UtmParameterFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="utmパラメータ" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-3">
          <div>
            <label className="text-c-base font-bold">utm-source</label>
            <div
              className={`flex items-center gap-2 rounded h-9 text-sm border border-c-border px-2`}>
              <MagnifyingGlassIcon className="w-4 h-4 text-c-lighter" />
              <input
                className="h-8 text-sm border-c-border flex-1 outline-none"
                defaultValue={query.utmSource || ''}
                onBlur={(e) => {
                  setQuery({...query, utmSource: e.target.value});
                }}
                onKeyDown={(e: any) => {
                  e.key === 'Enter' &&
                    setQuery({...query, utmSource: e.target.value});
                }}
              />
            </div>
          </div>
          <div>
            <label className="text-c-base font-bold">utm-medium</label>
            <div
              className={`flex items-center gap-2 rounded h-9 text-sm border border-c-border px-2`}>
              <MagnifyingGlassIcon className="w-4 h-4 text-c-lighter" />
              <input
                className="h-8 text-sm border-c-border flex-1 outline-none"
                defaultValue={query.utmMedium || ''}
                onBlur={(e) => {
                  setQuery({...query, utmMedium: e.target.value});
                }}
                onKeyDown={(e: any) => {
                  e.key === 'Enter' &&
                    setQuery({...query, utmMedium: e.target.value});
                }}
              />
            </div>
          </div>
          <div>
            <label className="text-c-base font-bold">utm-campaign</label>
            <div
              className={`flex items-center gap-2 rounded h-9 text-sm border border-c-border px-2`}>
              <MagnifyingGlassIcon className="w-4 h-4 text-c-lighter" />
              <input
                className="h-8 text-sm border-c-border flex-1 outline-none"
                defaultValue={query.utmCampaign || ''}
                onBlur={(e) => {
                  setQuery({...query, utmCampaign: e.target.value});
                }}
                onKeyDown={(e: any) => {
                  e.key === 'Enter' &&
                    setQuery({...query, utmCampaign: e.target.value});
                }}
              />
            </div>
          </div>
          <div>
            <label className="text-c-base font-bold">utm-term</label>
            <div
              className={`flex items-center gap-2 rounded h-9 text-sm border border-c-border px-2`}>
              <MagnifyingGlassIcon className="w-4 h-4 text-c-lighter" />
              <input
                className="h-8 text-sm border-c-border flex-1 outline-none"
                defaultValue={query.utmTerm || ''}
                onBlur={(e) => {
                  setQuery({...query, utmTerm: e.target.value});
                }}
                onKeyDown={(e: any) => {
                  e.key === 'Enter' &&
                    setQuery({...query, utmTerm: e.target.value});
                }}
              />
            </div>
          </div>
          <div>
            <label className="text-c-base font-bold">utm-content</label>
            <div
              className={`flex items-center gap-2 rounded h-9 text-sm border border-c-border px-2`}>
              <MagnifyingGlassIcon className="w-4 h-4 text-c-lighter" />
              <input
                className="h-8 text-sm border-c-border flex-1 outline-none"
                defaultValue={query.utmContent || ''}
                onBlur={(e) => {
                  setQuery({...query, utmContent: e.target.value});
                }}
                onKeyDown={(e: any) => {
                  e.key === 'Enter' &&
                    setQuery({...query, utmContent: e.target.value});
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer
        onClear={() => {
          setQuery({
            ...query,
            utmSource: null,
            utmMedium: null,
            utmCampaign: null,
            utmTerm: null,
            utmContent: null,
          });
        }}
      />
    </>
  );
};

export default UtmParameterFilter;
