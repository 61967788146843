import React from 'react';
import {
  useProspectScoreSettingQuery,
  useUpdateClientProspectScoreSettingMutation,
} from 'api';
import useDebounce from 'hooks/useDebounce';

export default function CustomSlider() {
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );
  const [update] = useUpdateClientProspectScoreSettingMutation({});
  const [weight, setWeight] = React.useState(
    prospectScoreSetting?.weightMailOpenedAt ?? null,
  );

  const debouncedValue = useDebounce<Number>(weight, 500);

  React.useEffect(() => {
    if (weight === null) {
      return;
    }
    update({
      variables: {
        attributes: {
          weightMailOpenedAt: Number(weight),
        },
      },
    });
  }, [debouncedValue]);

  return (
    <div className="w-40 max-w-sm flex items-center gap-2">
      <div className="flex items-center justify-center">
        <input
          type="range"
          className="cursor-pointer"
          value={weight ?? prospectScoreSetting?.weightMailOpenedAt}
          min={0}
          max={5}
          onChange={(e) => setWeight(Number(e.target.value))}
        />
      </div>
      <div className="text-base font-bold">
        {weight ?? prospectScoreSetting?.weightMailOpenedAt}
      </div>
    </div>
  );
}
