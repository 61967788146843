import React from 'react';

interface Props {
  note: string;
}

export default ({note}: Props) => {
  if (!note) return null;
  return (
    <div className="pb-2">
      <div className="px-2 bg-c-bg text-[#666666]">備考</div>
      <div className="pt-1 whitespace-pre-wrap">{note}</div>
    </div>
  );
};
