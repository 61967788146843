import React from 'react';
import {AccountKeyPerson, KeyPerson} from 'api';
import {Row} from 'react-table';
import SnsComponent from './Sns';

interface Props {
  row: Row<KeyPerson>;
}

export default ({row}: Props) => {
  const keyPerson = row.original;
  const accountKeyPerson: AccountKeyPerson = keyPerson.accountKeyPerson;

  return (
    <div className="flex items-center gap-2">
      {accountKeyPerson ? (
        <SnsComponent
          facebookUrl={accountKeyPerson.facebookUrl}
          linkedinUrl={accountKeyPerson.linkedinUrl}
          xUrl={accountKeyPerson.xUrl}
          wantedlyUrl={accountKeyPerson.wantedlyUrl}
          eightUrl={accountKeyPerson.eightUrl}
        />
      ) : (
        <SnsComponent
          facebookUrl={keyPerson.facebookUrl}
          linkedinUrl={keyPerson.linkedinUrl}
          xUrl={keyPerson.xUrl}
          wantedlyUrl={keyPerson.wantedlyUrl}
          eightUrl={keyPerson.eightUrl}
        />
      )}
    </div>
  );
};
