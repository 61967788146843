import React from 'react';
import {Form, Select} from 'components/antd';
import {useMailCampaignPoolProspectCustomizeItemTextsQuery} from 'api';
import {useParams} from 'react-router-dom';

interface SelectInputProps {
  formik: any;
}

const SelectInputComponent: React.FC<SelectInputProps> = ({formik}) => {
  const {mailCampaignId} = useParams<{mailCampaignId: string}>();

  const {data: {mailCampaignPoolProspectCustomizeItemTexts = []} = {}} =
    useMailCampaignPoolProspectCustomizeItemTextsQuery({
      variables: {uuid: mailCampaignId},
    });

  return (
    <Form.Item>
      <div className="space-y-2">
        <Select
          placeholder="カスタム項目名"
          onChange={(value) => {
            formik.setFieldValue('poolProspectCustomizeItemId', value);
          }}
          value={formik.values.poolProspectCustomizeItemId?.toString()}>
          <Select.Option key="empty" value="">
            {' '}
          </Select.Option>
          {mailCampaignPoolProspectCustomizeItemTexts.map(
            (item, index: number) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ),
          )}
        </Select>
        <p style={{color: '#666666'}}>
          ※カスタム項目を選択するとリンクとして設定され、上書きされます。
        </p>
      </div>
    </Form.Item>
  );
};

export default SelectInputComponent;
