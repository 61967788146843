import React from 'react';
import styled from 'styled-components';
import {
  useUpdateMailCampaignMutation,
  useReadyMailCampaignMutation,
  useCurrentMailCampaignContractQuery,
  MailCampaign,
  useMailCampaignsQuery,
} from 'api';
import {Input, Button} from 'components/antd';
import * as Yup from 'yup';
import {useRecoilValue} from 'recoil';
import totalCountAtom from './totalCountAtom';
import {useHistory} from 'react-router-dom';
import TestModal from './TestModal';
import Cancel from './Cancel';
import ActionComponent from '../Action';

interface Props {
  mailCampaign: MailCampaign;
}

export default ({mailCampaign}: Props) => {
  const [name, setName] = React.useState(mailCampaign.title);
  const [testModalVisible, setTestModalVisible] = React.useState(false);
  const total = useRecoilValue(totalCountAtom);
  const history = useHistory();
  const {data: {currentMailCampaignContract = {}} = {}} =
    useCurrentMailCampaignContractQuery({
      fetchPolicy: 'cache-and-network',
    });

  const {
    data: {mailCampaigns: {mailCampaigns: sendingMailCampaigns = []} = {}} = {},
  } = useMailCampaignsQuery({
    variables: {
      status: 'sending',
    },
    fetchPolicy: 'cache-and-network',
  });

  const [updateMailCampaign] = useUpdateMailCampaignMutation();
  const [readyMailCampaign] = useReadyMailCampaignMutation({
    onCompleted: (data) => {
      if (data.readyMailCampaign.invalidMessage) {
        alert(data.readyMailCampaign.invalidMessage);
      } else {
        history.push(`/mail_campaigns?target=${mailCampaign.target}`);
      }
    },
  });

  const mailCampaignSendsLimit =
    currentMailCampaignContract?.mailCampaignSendsLimit || 0;
  const mailCampaignSendsUsage =
    currentMailCampaignContract?.mailCampaignSendsUsage || 0;

  const updateName = (e: any) => {
    if (e.target.value) {
      updateMailCampaign({
        variables: {
          uuid: mailCampaign.uuid,
          attributes: {
            title: e.target.value,
          },
        },
      });
    } else {
      alert('メール名は必須です。');
      setName(mailCampaign.title);
    }
  };

  const updateStatus = () => {
    if (
      mailCampaignSendsLimit - mailCampaignSendsUsage < total &&
      mailCampaign.target === 'preleads'
    ) {
      alert(
        `今月のメール送信上限を超えています (${
          mailCampaignSendsUsage + total
        } / ${mailCampaignSendsLimit})`,
      );
    } else {
      confirm(
        sendingMailCampaigns.length > 0
          ? `送信中のメールキャンペーンが${sendingMailCampaigns.length}件あります。送信実行/予約を行ってよろしいですか？`
          : '送信実行/予約を行ってよろしいですか？',
      ) &&
        readyMailCampaign({
          variables: {uuid: mailCampaign.uuid},
        });
    }
  };

  const isValid = React.useMemo(
    () =>
      mailCampaign.status === 'draft' &&
      total > 0 &&
      Yup.object({
        title: Yup.string().required(),
        fromAddress: Yup.string().email().required(),
        fromName: Yup.string().required(),
        bodyHtml:
          mailCampaign.blocks?.length > 0
            ? Yup.string().nullable()
            : Yup.string().required(),
        isImmediately: Yup.boolean().required(),
        sendAt: Yup.date().when('isImmediately', {
          is: true,
          then: (schema) => schema.nullable(),
          otherwise: (schema) => schema.required(),
        }),
      }).isValidSync(mailCampaign),
    [mailCampaign, total, sendingMailCampaigns],
  );

  const isValidTestMail = React.useMemo(
    () =>
      mailCampaign.status === 'draft' &&
      total > 0 &&
      Yup.object({
        title: Yup.string().required(),
        fromAddress: Yup.string().email().required(),
        fromName: Yup.string().required(),
        bodyHtml:
          mailCampaign.blocks?.length > 0
            ? Yup.string().nullable()
            : Yup.string().required(),
      }).isValidSync(mailCampaign),
    [mailCampaign, total],
  );

  const statusStyle: {[key: string]: React.CSSProperties} = {
    draft: {
      background: '#EAECEE',
      color: 'var(--text-color-2)',
    },
    ready: {
      background: '#F9E3C8',
      color: '#FF8F00',
    },
    sent: {
      background: '#D0E8DA',
      color: '#4FCE82',
    },
    sending: {
      background: '#E5EEFA',
      color: '#2F80ED',
    },
    blastengine_ready: {
      background: '#F9E3C8',
      color: '#FF8F00',
    },
  };

  return (
    <Container>
      <div>
        <Status style={statusStyle[mailCampaign.status]}>
          {mailCampaign.statusText}
        </Status>{' '}
        <Name
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={updateName}
          onPressEnter={updateName}
        />
      </div>
      <ButtonWrapper>
        <Button
          type="primary"
          onClick={() => setTestModalVisible(true)}
          disabled={!isValidTestMail}>
          テストメール
        </Button>
        <Button type="primary" onClick={updateStatus} disabled={!isValid}>
          送信実行／予約する
        </Button>
        <Cancel mailCampaign={mailCampaign} />
        <ActionComponent mailCampaign={mailCampaign} placement="bottomRight" />
      </ButtonWrapper>
      <TestModal
        visible={testModalVisible}
        onClose={() => setTestModalVisible(false)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  & > div {
    display: flex;
    align-items: center;
    &:first-child {
      width: 100%;
    }
    div:first-child {
      margin-right: 15px;
    }
  }
`;

const Name = styled(Input)`
  font-size: 20px;
  font-weight: bold;
  margin: 1rem 0;
  border-color: transparent;
  background-color: transparent;
  border-radius: 4px;

  &:hover {
    border: 1px solid var(--text-color-3);
  }
`;

const Status = styled.span`
  display: flex;
  background: #d0e8da;
  color: #4fce82;
  height: 24px;
  font-size: 12px;
  width: 80px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  gap: 9px;
`;
