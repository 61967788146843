import {KeyPerson} from 'api';
import React from 'react';
import Menu from './Menu';
import Media from 'components/KeyPerson/Media';
import Name from 'components/KeyPerson/Name';
import Department from 'components/KeyPerson/Department';
import Position from 'components/KeyPerson/Position';
import TelephoneNumber from 'components/KeyPerson/TelephoneNumber';
import Note from 'components/KeyPerson/Note';
import SnsComponent from 'components/KeyPerson/Sns';

interface KeyPersonDetailParams {
  id: string;
  keyPerson: KeyPerson;
  fullName: string;
  department: string;
  position: string;
  facebookUrl: string;
  linkedinUrl: string;
  xUrl: string;
  wantedlyUrl: string;
  eightUrl: string;
  telephoneNumber: string;
  note: string;
}

interface Props {
  params: KeyPersonDetailParams;
  accountPoolUuid: string;
}

export default ({params, accountPoolUuid}: Props) => {
  const {
    id,
    keyPerson,
    fullName,
    department,
    position,
    facebookUrl,
    linkedinUrl,
    xUrl,
    wantedlyUrl,
    eightUrl,
    telephoneNumber,
    note,
  } = params;
  return (
    <div key={id} className="border-b border-c-border p-4">
      <div className="flex w-full items-center">
        <Name fullName={fullName} />
        <SnsComponent
          facebookUrl={facebookUrl}
          linkedinUrl={linkedinUrl}
          xUrl={xUrl}
          wantedlyUrl={wantedlyUrl}
          eightUrl={eightUrl}
        />
        <Menu keyPerson={keyPerson} accountPoolUuid={accountPoolUuid} />
      </div>
      <Department department={department} />
      <Position position={position} />
      <Media keyPerson={keyPerson} />
      <TelephoneNumber telephoneNumber={telephoneNumber} />
      <Note note={note} />
    </div>
  );
};
