import {MailCampaign} from 'api';
import React from 'react';
import {Row} from 'react-table';
import ActionComponent from '../../Action';

interface Props {
  row: Row<MailCampaign>;
}

const Cell = ({row}: Props) => {
  const mailCampaign = row.original;
  return <ActionComponent mailCampaign={mailCampaign} placement="left" />;
};

export default Cell;
