import React from 'react';
import {ProspectPool, useKeyPersonsByAccountPoolQuery} from 'api';
import KeyPersonComponent from './KeyPerson';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  if (!prospectPool) return null;

  const {data: {keyPersonsByAccountPool = []} = {}} =
    useKeyPersonsByAccountPoolQuery({
      variables: {
        uuid: prospectPool.accountPool.uuid,
      },
    });

  return (
    <>
      {keyPersonsByAccountPool.map((keyPerson) => (
        <KeyPersonComponent
          keyPerson={keyPerson}
          accountPoolUuid={prospectPool.accountPool.uuid}
          key={keyPerson.id}
        />
      ))}
    </>
  );
};
