import {
  NumberParam,
  StringParam,
  ArrayParam,
  useQueryParams,
  DelimitedArrayParam,
  BooleanParam,
  withDefault,
  encodeDelimitedArray,
  decodeDelimitedArray,
  NumericObjectParam,
  JsonParam,
} from 'use-query-params';

const PAGE_SIZE = 20;

const customizeItemFilterParam = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(
      array?.map((el) => JSON.stringify(el)),
      '&=',
    ),

  decode: (arrayStr: string | string[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, '&=')?.map((el) => JSON.parse(el)),
};

const searchParamsDef = {
  page: NumberParam,
  searchWord: StringParam,
  stage: StringParam,
  stages: DelimitedArrayParam,
  ranks: ArrayParam,
  workflowIds: withDefault(DelimitedArrayParam, []),
  leadSourceIds: DelimitedArrayParam,
  originalLeadSourceIds: DelimitedArrayParam,
  isUnsubscribed: withDefault(BooleanParam, null),
  tagIds: DelimitedArrayParam,
  excludedTagIds: DelimitedArrayParam,
  statuses: withDefault(DelimitedArrayParam, ['active']),
  workflow: BooleanParam,
  mailCampaignId: StringParam,
  isMailCampaignOpened: withDefault(BooleanParam, false),
  isMailCampaignClicked: withDefault(BooleanParam, false),
  formIds: DelimitedArrayParam,
  workflowMailOpenedFromDate: StringParam,
  workflowMailOpenedToDate: StringParam,
  workflowMailClickedFromDate: StringParam,
  workflowMailClickedToDate: StringParam,
  collectedFormUrlIds: withDefault(DelimitedArrayParam, []),
  assignees: DelimitedArrayParam,
  mailCampaignOpenedFromDate: StringParam,
  mailCampaignOpenedToDate: StringParam,
  mailCampaignClickedFromDate: StringParam,
  mailCampaignClickedToDate: StringParam,
  isBouncedMailCampaignSend: withDefault(BooleanParam, false),
  isUnsubscribedMailCampaignSend: withDefault(BooleanParam, false),
  isTargetAllMailCampaigns: withDefault(BooleanParam, false),
  isTargetAllWorkflows: withDefault(BooleanParam, false),
  telStatuses: ArrayParam,
  prospectCustomizeItemSearchConditions: withDefault(
    customizeItemFilterParam,
    [],
  ),
  trackedAtFrom: StringParam,
  trackedAtTo: StringParam,
  isBounced: withDefault(BooleanParam, null),
  telUpdatedDateFrom: withDefault(StringParam, ''),
  telUpdatedDateTo: withDefault(StringParam, ''),
  inflowDateType: withDefault(StringParam, 'latest'),
  inflowDateFrom: StringParam,
  inflowDateTo: StringParam,
  lastTelUserIds: withDefault(DelimitedArrayParam, []),
  mailCampaignUrlIds: withDefault(DelimitedArrayParam, []),
  presenceTelNumber: withDefault(BooleanParam, null),
  subCategories: withDefault(DelimitedArrayParam, []),
  listingMarkets: withDefault(DelimitedArrayParam, []),
  capitalFund: JsonParam,
  cityIds: withDefault(DelimitedArrayParam, []),
  employeeNumber: NumericObjectParam,
  isExistTelActivity: withDefault(BooleanParam, null),
  salesforceCampaignId: StringParam,
  salesforceCampaignSearchConditions: withDefault(customizeItemFilterParam, []),
  salesforceDealRecordTypeId: StringParam,
  salesforceDealUserId: StringParam,
  salesforceDealSearchConditions: withDefault(customizeItemFilterParam, []),
  salesforceContactRecordTypeId: StringParam,
  salesforceContactSearchConditions: withDefault(customizeItemFilterParam, []),
  salesforceLeadRecordTypeId: StringParam,
  salesforceLeadSearchConditions: withDefault(customizeItemFilterParam, []),
  salesforceLeadFieldSelectStatusIds: withDefault(DelimitedArrayParam, []),
  phases: ArrayParam,
  establishedDate: JsonParam,
  hasCompany: withDefault(BooleanParam, null),
  prospectScore: NumericObjectParam,
  utmCampaign: StringParam,
  utmSource: StringParam,
  utmMedium: StringParam,
  utmContent: StringParam,
  utmTerm: StringParam,
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);
  const {page} = query;

  const searchParams = {
    stage: query.stage,
    stages: query.stages,
    searchWord: query.searchWord,
    ranks: query.ranks,
    workflowIds: query.workflowIds,
    leadSourceIds: query.leadSourceIds,
    originalLeadSourceIds: query.originalLeadSourceIds,
    isUnsubscribed: query.isUnsubscribed,
    tagIds: query.tagIds,
    excludedTagIds: query.excludedTagIds,
    statuses: query.statuses,
    workflow: query.workflow,
    mailCampaignId: query.mailCampaignId,
    isMailCampaignOpened: query.isMailCampaignOpened,
    isMailCampaignClicked: query.isMailCampaignClicked,
    formIds: query.formIds,
    workflowMailOpenedFromDate: query.workflowMailOpenedFromDate,
    workflowMailOpenedToDate: query.workflowMailOpenedToDate,
    workflowMailClickedFromDate: query.workflowMailClickedFromDate,
    workflowMailClickedToDate: query.workflowMailClickedToDate,
    collectedFormUrlIds: query.collectedFormUrlIds,
    assignees: query.assignees,
    mailCampaignOpenedFromDate: query.mailCampaignOpenedFromDate,
    mailCampaignOpenedToDate: query.mailCampaignOpenedToDate,
    mailCampaignClickedFromDate: query.mailCampaignClickedFromDate,
    mailCampaignClickedToDate: query.mailCampaignClickedToDate,
    isBouncedMailCampaignSend: query.isBouncedMailCampaignSend,
    isUnsubscribedMailCampaignSend: query.isUnsubscribedMailCampaignSend,
    isTargetAllMailCampaigns: query.isTargetAllMailCampaigns,
    isTargetAllWorkflows: query.isTargetAllWorkflows,
    telStatuses: query.telStatuses,
    prospectCustomizeItemSearchConditions:
      query.prospectCustomizeItemSearchConditions,
    trackedAtFrom: query.trackedAtFrom,
    trackedAtTo: query.trackedAtTo,
    isBounced: query.isBounced,
    telUpdatedDateFrom: query.telUpdatedDateFrom,
    telUpdatedDateTo: query.telUpdatedDateTo,
    inflowDateType: query.inflowDateType,
    inflowDateFrom: query.inflowDateFrom,
    inflowDateTo: query.inflowDateTo,
    lastTelUserIds: query.lastTelUserIds,
    mailCampaignUrlIds: query.mailCampaignUrlIds,
    presenceTelNumber: query.presenceTelNumber,
    subCategories: query.subCategories,
    listingMarkets: query.listingMarkets,
    employeeNumber: query.employeeNumber,
    isExistTelActivity: query.isExistTelActivity,
    salesforceCampaignId: query.salesforceCampaignId,
    salesforceCampaignSearchConditions:
      query.salesforceCampaignSearchConditions,
    salesforceDealRecordTypeId: query.salesforceDealRecordTypeId,
    salesforceDealUserId: query.salesforceDealUserId,
    salesforceDealSearchConditions: query.salesforceDealSearchConditions,
    salesforceContactRecordTypeId: query.salesforceContactRecordTypeId,
    salesforceContactSearchConditions: query.salesforceContactSearchConditions,
    salesforceLeadRecordTypeId: query.salesforceLeadRecordTypeId,
    salesforceLeadSearchConditions: query.salesforceLeadSearchConditions,
    salesforceLeadFieldSelectStatusIds:
      query.salesforceLeadFieldSelectStatusIds,
    phases: query.phases,
    capitalFund: query.capitalFund,
    cityIds: query.cityIds,
    establishedDate: query.establishedDate,
    hasCompany: query.hasCompany,
    prospectScore: query.prospectScore,
    utmCampaign: query.utmCampaign,
    utmSource: query.utmSource,
    utmMedium: query.utmMedium,
    utmContent: query.utmContent,
    utmTerm: query.utmTerm,
  };

  const setPage = (page: number) => setQuery({page});

  let numberOfFiltersInUse = 0;
  if (query.stages?.length > 0) numberOfFiltersInUse++;
  if (query.ranks?.length > 0) numberOfFiltersInUse++;
  if (query.workflowIds?.length > 0) numberOfFiltersInUse++;
  if (query.leadSourceIds?.length > 0) numberOfFiltersInUse++;
  if (query.originalLeadSourceIds?.length > 0) numberOfFiltersInUse++;
  if (query.isUnsubscribed) numberOfFiltersInUse++;
  if (query.tagIds?.length > 0) numberOfFiltersInUse++;
  if (query.statuses?.length > 0) numberOfFiltersInUse++;
  if (query.mailCampaignId) numberOfFiltersInUse++;
  if (query.isMailCampaignOpened) numberOfFiltersInUse++;
  if (query.isMailCampaignClicked) numberOfFiltersInUse++;
  if (query.formIds?.length > 0) numberOfFiltersInUse++;
  if (query.workflowMailOpenedFromDate || query.workflowMailOpenedToDate)
    numberOfFiltersInUse++;
  if (query.workflowMailClickedFromDate || query.workflowMailClickedToDate)
    numberOfFiltersInUse++;
  if (query.collectedFormUrlIds?.length > 0) numberOfFiltersInUse++;
  if (query.assignees?.length > 0) numberOfFiltersInUse++;
  if (query.mailCampaignOpenedFromDate || query.mailCampaignOpenedToDate)
    numberOfFiltersInUse++;
  if (query.mailCampaignClickedFromDate || query.mailCampaignClickedToDate)
    numberOfFiltersInUse++;
  if (query.trackedAtFrom || query.trackedAtTo) numberOfFiltersInUse++;
  if (query.excludedTagIds?.length > 0) numberOfFiltersInUse++;
  if (query.inflowDateFrom || query.inflowDateTo) numberOfFiltersInUse++;
  if (query.mailCampaignUrlIds?.length > 0) numberOfFiltersInUse++;
  if (query.presenceTelNumber) numberOfFiltersInUse++;
  if (query.subCategories.length > 0) numberOfFiltersInUse++;
  if (query.listingMarkets.length > 0) numberOfFiltersInUse++;
  if (query.employeeNumber?.from || query.employeeNumber?.to)
    numberOfFiltersInUse++;
  if (query.isExistTelActivity) numberOfFiltersInUse++;
  if (query.salesforceCampaignId) numberOfFiltersInUse++;
  if (query.salesforceCampaignSearchConditions?.length > 0)
    numberOfFiltersInUse++;
  if (query.salesforceDealRecordTypeId) numberOfFiltersInUse++;
  if (query.salesforceDealUserId) numberOfFiltersInUse++;
  if (query.salesforceDealSearchConditions?.length > 0) numberOfFiltersInUse++;
  if (query.phases?.length > 0) numberOfFiltersInUse++;
  if (query.salesforceContactRecordTypeId) numberOfFiltersInUse++;
  if (query.salesforceContactSearchConditions?.length > 0)
    numberOfFiltersInUse++;
  if (query.capitalFund?.from || query.capitalFund?.to) numberOfFiltersInUse++;
  if (query.cityIds?.length > 0) numberOfFiltersInUse++;
  if (query.salesforceLeadRecordTypeId) numberOfFiltersInUse++;
  if (query.salesforceLeadSearchConditions?.length > 0) numberOfFiltersInUse++;
  if (query.isUnsubscribedMailCampaignSend) numberOfFiltersInUse++;
  if (query.salesforceLeadFieldSelectStatusIds?.length > 0)
    numberOfFiltersInUse++;
  if (query.establishedDate?.from || query.establishedDate?.to)
    numberOfFiltersInUse++;
  if (query.hasCompany) numberOfFiltersInUse++;
  if (query.prospectScore?.from || query.prospectScore?.to)
    numberOfFiltersInUse++;
  return {
    query,
    setQuery,
    searchParams: searchParams,
    page,
    perPage: PAGE_SIZE,
    setPage,
    numberOfFiltersInUse,
  };
};

export default useSearchParams;
