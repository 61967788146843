import {Button, Popover} from 'components/antd';
import mailVariableOptions from 'helpers/mailVariableOptions';
import React from 'react';
import styled from 'styled-components';

interface Props {
  onSelect: (variable: string) => void;
}

export default ({onSelect}: Props) => {
  const [variablePopoverVisible, setVariablePopoverVisible] =
    React.useState(false);
  return (
    <Popover
      content={
        <PopoverContent>
          {mailVariableOptions.map((option) => (
            <PopoverSelectButtonWrapper key={option.title}>
              <PopoverSelectButton onClick={() => onSelect(option.code)}>
                <span>{option.code}</span>
                <span>{option.title}</span>
              </PopoverSelectButton>
            </PopoverSelectButtonWrapper>
          ))}
        </PopoverContent>
      }
      trigger="click"
      visible={variablePopoverVisible}
      placement="bottomRight"
      onVisibleChange={setVariablePopoverVisible}>
      <PopoverDisplayButton>{`{ }`}</PopoverDisplayButton>
    </Popover>
  );
};

const PopoverDisplayButton = styled(Button)`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const PopoverSelectButtonWrapper = styled.div`
  display: flex;
  width: 235px;
  height: 30px;
  span: first-child {
    color: var(--text-color-2);
    margin-right: 10px;
  }
  span: last-child {
    color: var(--text-color-1);
  }
`;

const PopoverSelectButton = styled(Button)`
  padding: 0;
  padding-left: 10px;
  width: 235px;
  text-align: left;
  border: none;

  &:hover {
    background: #f1f8ff;
  }
`;
