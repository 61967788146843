import gql from 'graphql-tag';


export default gql`
  fragment blastengineMailCampaignMailClickActivityFragment on BlastengineMailCampaignMailClickActivity {
    id
    mailCampaignUrl {
      id
      url
    }
    mailCampaignSend {
      id
      createdAt
      mailCampaign {
        id
        uuid
        title
        subject
        fromAddress
        fromName
        cc
        bcc
        sendAt
        isImmediately
        bodyHtml
        pool {
          uuid
        }
      }
    }
  }
`;
