import React from 'react';

interface IconProps {
  className?: string;
}

const Icon: React.FC<IconProps> = ({className}) => (
  <svg
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_58585_8125)">
      <path
        d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6 3C6.31172 3 6.5625 3.25078 6.5625 3.5625V6.1875C6.5625 6.49922 6.31172 6.75 6 6.75C5.68828 6.75 5.4375 6.49922 5.4375 6.1875V3.5625C5.4375 3.25078 5.68828 3 6 3ZM5.25 8.25C5.25 8.05109 5.32902 7.86032 5.46967 7.71967C5.61032 7.57902 5.80109 7.5 6 7.5C6.19891 7.5 6.38968 7.57902 6.53033 7.71967C6.67098 7.86032 6.75 8.05109 6.75 8.25C6.75 8.44891 6.67098 8.63968 6.53033 8.78033C6.38968 8.92098 6.19891 9 6 9C5.80109 9 5.61032 8.92098 5.46967 8.78033C5.32902 8.63968 5.25 8.44891 5.25 8.25Z"
        fill="#F2994A"
      />
    </g>
    <defs>
      <clipPath id="clip0_58585_8125">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
