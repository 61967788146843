import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignBlockFooterFragment on MailCampaignBlockFooter {
    id
    textHtml
    bodyColor
    textColor
    paddingTop
    paddingBottom
    paddingLeft
    paddingRight
    lineHeight
    createdAt
    updatedAt
  }
`;
