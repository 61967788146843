import React from 'react';
import {useProspectScoreSettingQuery} from 'api';
import Item from './Item';

const List = () => {
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );
  const items =
    prospectScoreSetting?.clientProspectScoreSettingCustomizeItems ?? [];

  return (
    <>
      {items?.map((item) => {
        return <Item customizeItem={item.poolProspectCustomizeItem} />;
      })}
    </>
  );
};

export default List;
