import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  if (
    !query?.utmCampaign &&
    !query?.utmMedium &&
    !query?.utmSource &&
    !query?.utmTerm &&
    !query?.utmContent
  ) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">utmパラメータ</h4>
        <button
          onClick={() => setFilterType('utmParameter')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({
              ...query,
              utmCampaign: null,
              utmMedium: null,
              utmSource: null,
              utmTerm: null,
              utmContent: null,
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div>
        {query.utmCampaign && (
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2 mr-1">
            utmCampaign: {query.utmCampaign}
          </span>
        )}
        {query.utmMedium && (
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2 mr-1">
            utmMedium: {query.utmMedium}
          </span>
        )}
        {query.utmSource && (
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2 mr-1">
            utmSource: {query.utmSource}
          </span>
        )}
        {query.utmTerm && (
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2 mr-1">
            utmTerm: {query.utmTerm}
          </span>
        )}
        {query.utmContent && (
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2 mr-1">
            utmContent: {query.utmContent}
          </span>
        )}
      </div>
    </div>
  );
};
