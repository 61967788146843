import React from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import Menu from '../Menu';
import {sideMenuWidth} from 'helpers/sideMenu';
import {useCurrentUserQuery} from 'api';
import ProspectTag from './ProspectTag';

export default () => {
  const history = useHistory();
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});

  return (
    <Body>
      <Menu />
      <Content>
        <Tabs>
          <Tab
            active={location.pathname.includes('/tag/prospect_tag')}
            onClick={() => history.push(`/settings/tag/prospect_tag`)}>
            <span>リード</span>
          </Tab>
        </Tabs>
        <Switch>
          <Route path="/settings/tag/prospect_tag" component={ProspectTag} />
        </Switch>
      </Content>
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  background: #fff;
  height: calc(100vh - 60px);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 70px 90px;
  width: calc(100vw - (290px + ${sideMenuWidth}px));
  overflow: scroll;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  border-bottom: 1px solid #e1e6eb;
`;

const Tab = styled.div<{active?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 97px;

  span {
    margin-right: 32px;
    padding: 9px 0;
    display: block;
    height: 100%;
    border-bottom: ${({active}) => (active ? 'solid 3px var(--primary)' : '')};
    font-weight: bold;
    font-size: 14px;
    color: ${({active}) =>
      active ? 'var(--text-color-1)' : 'var(--text-color-3)'};
  }

  &:hover {
    cursor: pointer;
  }
`;
