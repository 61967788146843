import React from 'react';
import {
  useAccountScoreSettingQuery,
  AccountScoreSettingWebsite,
  useUpdateClientAccountScoreSettingWebsiteTypeMutation,
} from 'api';

export default () => {
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery(
    {},
  );
  const [update] = useUpdateClientAccountScoreSettingWebsiteTypeMutation({});
  const selectedWebsiteTypes =
    accountScoreSetting?.clientAccountScoreSettingWebsiteTypes?.map(
      (clientAccountScoreSettingWebsiteType: AccountScoreSettingWebsite) =>
        clientAccountScoreSettingWebsiteType.pageType,
    ) ?? [];

  const options = [
    {name: '新卒採用', value: 'recruitnew'},
    {name: '中途採用', value: 'recruit'},
    {name: 'アルバイト', value: 'parttime'},
    {name: 'プレスリリース', value: 'press'},
  ];

  return (
    <div className="flex flex-col gap-2 text-sm">
      {options.map((option) => {
        return (
          <label
            className="flex items-center text-base cursor-pointer"
            key={option.value}>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 rounded border-c-border cursor-pointer"
              onChange={() => {
                update({
                  variables: {
                    attributes: {
                      pageType: option.value,
                    },
                  },
                });
              }}
              checked={selectedWebsiteTypes?.includes(option.value)}
            />
            <span className="ml-2">{option.name}</span>
          </label>
        );
      })}
    </div>
  );
};
