import React from 'react';
import FacebookIcon from 'components/Ui/Icon/FacebookIcon';
import XcomIcon from 'components/Ui/Icon/XcomIcon';
import WantedIcon from 'components/Ui/Icon/WantedIcon';
import LinkedInIcon from 'components/Ui/Icon/LinkedInIcon';
import EightIcon from 'components/Ui/Icon/EightIcon';

interface Props {
  facebookUrl: string;
  linkedinUrl: string;
  xUrl: string;
  wantedlyUrl: string;
  eightUrl: string;
}

export default ({
  facebookUrl,
  linkedinUrl,
  xUrl,
  wantedlyUrl,
  eightUrl,
}: Props) => {
  return (
    <div className="flex items-center justify-center gap-2 leading-none mb-2">
      {facebookUrl && (
        <a href={facebookUrl} target="_href" className="cursor-pointer">
          <FacebookIcon />
        </a>
      )}
      {linkedinUrl && (
        <a href={linkedinUrl} target="_href" className="cursor-pointer">
          <LinkedInIcon />
        </a>
      )}
      {xUrl && (
        <a href={xUrl} target="_href" className="cursor-pointer">
          <XcomIcon />
        </a>
      )}
      {wantedlyUrl && (
        <a href={wantedlyUrl} target="_href" className="cursor-pointer">
          <WantedIcon />
        </a>
      )}
      {eightUrl && (
        <a href={eightUrl} target="_href" className="cursor-pointer">
          <EightIcon />
        </a>
      )}
    </div>
  );
};
