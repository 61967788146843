import React from 'react';
import {
  useAccountScoreSettingQuery,
  useCreateClientAccountScoreSettingWebsiteTypeKeywordMutation,
  useDestroyClientAccountScoreSettingWebsiteTypeKeywordMutation,
} from 'api';
import {Combobox} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';

export default () => {
  const [query, setQuery] = React.useState('');
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery(
    {},
  );
  const [createKeyword] =
    useCreateClientAccountScoreSettingWebsiteTypeKeywordMutation({});
  const [destroyKeyword] =
    useDestroyClientAccountScoreSettingWebsiteTypeKeywordMutation({});
  const alreadlyCreatedKeywords =
    accountScoreSetting?.clientAccountScoreSettingWebsiteTypeKeywords?.map(
      (keword) => keword.name,
    );

  return (
    <>
      <div className="w-full">
        <h1 className="text-gray-700 text-sm font-bold">重要キーワード設定</h1>
        <Combobox value={null}>
          <div className="relative mt-1 flex items-center flex-shrink-0 gap-1">
            <div className="p-2 border border-c-border rounded-sm flex gap-2 w-full items-center flex-wrap">
              {accountScoreSetting?.clientAccountScoreSettingWebsiteTypeKeywords?.map(
                (keyword: any) => (
                  <span
                    key={keyword.id}
                    className="bg-c-bg h-6 rounded text-sm px-4 flex items-center gap-1">
                    {keyword.name}
                    <button
                      onClick={() => {
                        destroyKeyword({
                          variables: {
                            id: keyword.id,
                          },
                        });
                      }}
                      className="flex items-center justify-center cursor-pointer bg-c-bg">
                      <XMarkIcon className="h-4 w-4" />
                    </button>
                  </span>
                ),
              )}
              <Combobox.Input
                className="flex-1 outline-none w-full"
                value={query}
                displayValue={() => query}
                onChange={(event) => setQuery(event.target.value)}
                onKeyDown={(e: any) => {
                  if (
                    e.key === 'Enter' &&
                    e.nativeEvent.isComposing === false &&
                    !alreadlyCreatedKeywords?.includes(query)
                  ) {
                    createKeyword({
                      variables: {
                        name: query,
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
        </Combobox>
      </div>
    </>
  );
};
