import React from 'react';
import {
  useUpdateCollectedFormUrlMutation,
  CollectedFormUrl,
  useLeadSourcesQuery,
} from 'api';

interface Props {
  collectedFormUrl: CollectedFormUrl;
}

export default ({collectedFormUrl}: Props) => {
  const {data: {leadSources = []} = {}} = useLeadSourcesQuery();
  const [value, setValue] = React.useState(collectedFormUrl.leadSourceId || '');

  const [updateCollectedFormUrl] = useUpdateCollectedFormUrlMutation();

  React.useEffect(() => {
    value !== collectedFormUrl.leadSourceId &&
      updateCollectedFormUrl({
        variables: {
          collectedFormUrlUuid: collectedFormUrl.uuid,
          attributes: {
            leadSourceId: value,
          },
        },
      });
  }, [collectedFormUrl, value]);

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-1">
        <label className="text-c-base font-bold">リードソース</label>
        <p className="text-c-base text-sm m-0">
          フォームから問合せがあったリードに付与するリードソースの種類を設定できます。
        </p>
        <select
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="form-select border-c-border rounded text-base w-full">
          <option value=""></option>
          {leadSources.map((leadSource) => (
            <option key={leadSource.id} value={leadSource.id}>
              {leadSource.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
