import {Popover} from 'antd';
import {TooltipPlacement} from 'antd/lib/tooltip';
import {
  MailCampaign,
  useCopyMailCampaignMutation,
  useDestroyMailCampaignMutation,
} from 'api';
import {Copy, DotMenu, Trash} from 'components/Ui/Icon';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  mailCampaign: MailCampaign;
  placement: TooltipPlacement;
}

const ActionComponent = ({mailCampaign, placement}: Props) => {
  const history = useHistory();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [copyMailCampaign] = useCopyMailCampaignMutation({
    variables: {uuid: mailCampaign.uuid},
    refetchQueries: ['mailCampaigns'],
    onCompleted: () => {
      history.push(
        `/mail_campaigns?page=1&status&target=${mailCampaign.target}`,
      );
    },
  });
  const [destroyMailCampaign] = useDestroyMailCampaignMutation({
    variables: {uuid: mailCampaign.uuid},
    refetchQueries: ['mailCampaigns'],
    onCompleted: () => {
      history.push(
        `/mail_campaigns?page=1&status&target=${mailCampaign.target}`,
      );
    },
  });

  const Content = () => (
    <Container>
      <Action
        onClick={() => {
          confirm('コピーしてもよろしいですか？') ? copyMailCampaign() : null;
          setIsPopoverVisible(false);
        }}>
        <Copy />
        コピー
      </Action>
      {mailCampaign.status === 'draft' && (
        <Action
          onClick={() => {
            confirm('削除してもよろしいですか？')
              ? destroyMailCampaign()
              : null;
            setIsPopoverVisible(false);
          }}>
          <Trash />
          削除
        </Action>
      )}
    </Container>
  );

  return (
    <Popover
      placement={placement}
      content={<Content />}
      trigger="click"
      onVisibleChange={(visible) => setIsPopoverVisible(visible)}
      visible={isPopoverVisible}>
      <IconWrapper>
        <DotMenu />
      </IconWrapper>
    </Popover>
  );
};

const IconWrapper = styled.div`
  cursor: pointer;
  width: 25px;
  svg {
    transform: rotate(90deg);
    position: relative;
    top: 3px;
    left: 12px;
  }
`;

const Container = styled.div`
  width: 150px;
  cursor: pointer;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding-left: 18px;
  width: 100%;
  height: 50px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export default ActionComponent;
