import React from 'react';
import CheckboxGroup from './CheckboxGroup';
import {
  useClientPhaseProspectsQuery,
  ClientPhaseProspect,
  useProspectScoreSettingQuery,
  useCreateClientProspectScoreSettingPhaseProspectMutation,
  useDestroyClientProspectScoreSettingPhaseProspectMutation,
} from 'api';

interface Props {
  priority: number;
}

export default ({priority}: Props) => {
  const [create] = useCreateClientProspectScoreSettingPhaseProspectMutation({});
  const [destroy] = useDestroyClientProspectScoreSettingPhaseProspectMutation(
    {},
  );

  const {data: {clientPhaseProspects = []} = {}} = useClientPhaseProspectsQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );

  const optionProspectPhases = [
    ...clientPhaseProspects.map((phase: ClientPhaseProspect) => ({
      text: phase.name,
      value: phase.id,
      keyword: phase.name,
    })),
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target;
    const variables = {
      priority,
      clientPhaseProspectId: value,
    };
    if (checked) {
      create({
        variables,
      });
    } else {
      destroy({
        variables,
      });
    }
  };

  return (
    <CheckboxGroup
      filter
      options={optionProspectPhases}
      values={prospectScoreSetting?.clientProspectScoreSettingPhaseProspectsByPriority?.[
        priority
      ]?.map((phaseProspect: ClientPhaseProspect) => String(phaseProspect.id))}
      allSelected={prospectScoreSetting?.clientPhaseProspects?.map(
        (phase) => phase?.id,
      )}
      onChange={handleChange}
    />
  );
};
