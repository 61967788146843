import React from 'react';
import SelectedProspectPhases from '../SelectedProspectPhases';

export default () => {
  return (
    <>
      <div className="bg-[#F3F5F7] border-c-border w-32 text-center mb-2">
        選択中のフェーズ
      </div>
      <SelectedProspectPhases />
    </>
  );
};
