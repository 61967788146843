import React from 'react';
import {PoolProspectCustomizeItem} from 'api';

interface Props {
  customizeItem: PoolProspectCustomizeItem;
}

export default ({customizeItem}: Props) => {
  const customizeItemName = customizeItem?.name;
  return (
    <>
      <div className="flex flex-col gap-4 p-4 bg-white">
        <div className="flex flex-col gap-2 items-start">
          <div className="flex w-full items-center justify-between">
            <div>
              <h2 className="text-base m-0 font-bold">{customizeItemName}</h2>
              <p className="m-0 text-sm">
                {`重要度を高める${customizeItemName}を選択してください。`}
              </p>
            </div>
          </div>
          <button
            className="bg-white border border-c-border rounded h-8 text-sm px-4 cursor-pointer"
            onClick={() => {}}>
            {`${customizeItemName}を選択`}
          </button>
        </div>
      </div>
    </>
  );
};
