import gql from 'graphql-tag';

export default gql`
  fragment accountScoreSettingFragment on AccountScoreSetting {
    id
    subCategoryIds
    weightSubCategory
    weightEmployeeRange
    employeeRangesGroupByPriority
    capitalFundRangesGroupByPriority
    subCategoriesGroupByPriority
    weightCapitalFundRange
    weightAccountClosingMonth
    weightWebsiteType
    updateStatus
    subCategories {
      id
      displayName
      mainCategoryId
    }
    employeeRanges {
      id
      enumValue
    }
    capitalFundRanges {
      id
      enumValue
    }
    clientAccountScoreSettingAccountClosingMonths {
      id
      priority
      beforeMonth
    }
    clientAccountScoreSettingWebsiteTypes {
      id
      pageType
    }
    clientAccountScoreSettingWebsiteTypeKeywords {
      id
      name
    }
  }
`;
