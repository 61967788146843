import gql from 'graphql-tag';
import commentActivityFragment from './comment';
import mailCommentActivityFragment from './mailComment';
import mailSendActivityFragment from './mailSend';
import mailFormSendActivityFragment from './mailFormSend';
import mailClickReportActivityFragment from './mailClickReport';
import telCommentActivityFragment from './telComment';
import statusChangeActivityFragment from './statusChange';
import responseStatusChangeActivityFragment from './responseStatusChange';
import responseStatusAndCommentActivityFragment from './responseStatusAndComment';
import telStatusChangeActivityFragment from './telStatusChange';
import telStatusAndCommentActivityFragment from './telStatusAndComment';
import preleadContactCreateActivityFragment from './preleadContactCreate';
import preleadProjectReminderCreateActivityFragment from './preleadProjectReminderCreate';
import salesforceLeadCreateActivityFragment from './salesforceLeadCreate';
import hubspotDealCreateActivityFragment from './hubspotDealCreate';
import hubspotContactCreateActivityFragment from './hubspotContactCreate';
import sensesDealCreateActivityFragment from './sensesDealCreate';
import sensesContactCreateActivityFragment from './sensesContactCreate';
import letterSendActivityFragment from './letterSend';
import letterStatusChangeActivityFragment from './letterStatusChange';
import mailFormVersionUrlClickActivityFragment from './mailFormVersionUrlClick';
import mailFormVersionPreleadProjectUrlClickActivityFragment from './mailFormVersionPreleadProjectUrlClick';
import userMailActivityFragment from './userMail';
import assignUserActivityFragment from './assignUser';
import activityAttachmentFragment from '../activityAttachment';
import extMailFormSendActivityFragment from './extMailFormSend';
import miitelCallActivityFragment from './miitelCall';
import zoomPhoneWebhookCallActivityFragment from './zoomPhoneWebhookCall';
import mailCampaignMailSendActivityFragment from './mailCampaignMailSend';
import mailCampaignMailOpenActivityFragment from './mailCampaignMailOpen';
import mailCampaignMailClickActivityFragment from './mailCampaignMailClick';
import mailCampaignMailBounceActivityFragment from './mailCampaignMailBounce';
import unsubscribeMailCampaignActivityFragment from './unsubscribeMailCampaign';
import prospectCreateActivityFragment from './prospectCreate';
import miitelCallPreleadActivityFragment from './miitelCallPrelead';
import blastengineMailCampaignMailClickActivityFragment from './blastengineMailCampaignMailClick';

export default gql`
  fragment activityFragment on Activity {
    id
    uuid
    resourceId
    resourceType
    createdAt
    preleadProjectId
    project {
      uuid
      projectGroupId
      name
      projectGroup {
        uuid
        isPrivate
      }
    }
    activityAttachments {
      ...activityAttachmentFragment
    }
    resource {
      ... on CommentActivity {
        ...commentActivityFragment
      }
      ... on MailCommentActivity {
        ...mailCommentActivityFragment
      }
      ... on MailSendActivity {
        ...mailSendActivityFragment
      }
      ... on MailFormSendActivity {
        ...mailFormSendActivityFragment
      }
      ... on MailClickReportActivity {
        ...mailClickReportActivityFragment
      }
      ... on TelCommentActivity {
        ...telCommentActivityFragment
      }
      ... on StatusChangeActivity {
        ...statusChangeActivityFragment
      }
      ... on ResponseStatusChangeActivity {
        ...responseStatusChangeActivityFragment
      }
      ... on ResponseStatusAndCommentActivity {
        ...responseStatusAndCommentActivityFragment
      }
      ... on TelStatusChangeActivity {
        ...telStatusChangeActivityFragment
      }
      ... on TelStatusAndCommentActivity {
        ...telStatusAndCommentActivityFragment
      }
      ... on PreleadContactCreateActivity {
        ...preleadContactCreateActivityFragment
      }
      ... on PreleadProjectReminderCreateActivity {
        ...preleadProjectReminderCreateActivityFragment
      }
      ... on SalesforceLeadCreateActivity {
        ...salesforceLeadCreateActivityFragment
      }
      ... on HubspotDealCreateActivity {
        ...hubspotDealCreateActivityFragment
      }
      ... on HubspotContactCreateActivity {
        ...hubspotContactCreateActivityFragment
      }
      ... on SensesDealCreateActivity {
        ...sensesDealCreateActivityFragment
      }
      ... on SensesContactCreateActivity {
        ...sensesContactCreateActivityFragment
      }
      ... on LetterSendActivity {
        ...letterSendActivityFragment
      }
      ... on LetterStatusChangeActivity {
        ...letterStatusChangeActivityFragment
      }
      ... on MailFormVersionUrlClickActivity {
        ...mailFormVersionUrlClickActivityFragment
      }
      ... on MailFormVersionPreleadProjectUrlClickActivity {
        ...mailFormVersionPreleadProjectUrlClickActivityFragment
      }
      ... on UserMailActivity {
        ...userMailActivityFragment
      }
      ... on AssignUserActivity {
        ...assignUserActivityFragment
      }
      ... on ExtMailFormSendActivity {
        ...extMailFormSendActivityFragment
      }
      ... on MiitelCallActivity {
        ...miitelCallActivityFragment
      }
      ... on ZoomPhoneWebhookCallActivity {
        ...zoomPhoneWebhookCallActivityFragment
      }
      ... on MailCampaignMailSendActivity {
        ...mailCampaignMailSendActivityFragment
      }
      ... on MailCampaignMailOpenActivity {
        ...mailCampaignMailOpenActivityFragment
      }
      ... on MailCampaignMailClickActivity {
        ...mailCampaignMailClickActivityFragment
      }
      ... on MailCampaignMailBounceActivity {
        ...mailCampaignMailBounceActivityFragment
      }
      ... on UnsubscribeMailCampaignActivity {
        ...unsubscribeMailCampaignActivityFragment
      }
      ... on ProspectCreateActivity {
        ...prospectCreateActivityFragment
      }
      ... on MiitelCallPreleadActivity {
        ...miitelCallPreleadActivityFragment
      }
      ... on BlastengineMailCampaignMailClickActivity {
        ...blastengineMailCampaignMailClickActivityFragment
      }
    }
  }
  ${commentActivityFragment}
  ${mailCommentActivityFragment}
  ${mailSendActivityFragment}
  ${mailFormSendActivityFragment}
  ${mailClickReportActivityFragment}
  ${telCommentActivityFragment}
  ${statusChangeActivityFragment}
  ${responseStatusChangeActivityFragment}
  ${responseStatusAndCommentActivityFragment}
  ${telStatusChangeActivityFragment}
  ${telStatusAndCommentActivityFragment}
  ${preleadContactCreateActivityFragment}
  ${preleadProjectReminderCreateActivityFragment}
  ${salesforceLeadCreateActivityFragment}
  ${hubspotDealCreateActivityFragment}
  ${hubspotContactCreateActivityFragment}
  ${sensesDealCreateActivityFragment}
  ${sensesContactCreateActivityFragment}
  ${letterSendActivityFragment}
  ${letterStatusChangeActivityFragment}
  ${mailFormVersionUrlClickActivityFragment}
  ${mailFormVersionPreleadProjectUrlClickActivityFragment}
  ${userMailActivityFragment}
  ${assignUserActivityFragment}
  ${activityAttachmentFragment}
  ${extMailFormSendActivityFragment}
  ${miitelCallActivityFragment}
  ${zoomPhoneWebhookCallActivityFragment}
  ${mailCampaignMailSendActivityFragment}
  ${mailCampaignMailOpenActivityFragment}
  ${mailCampaignMailClickActivityFragment}
  ${mailCampaignMailBounceActivityFragment}
  ${unsubscribeMailCampaignActivityFragment}
  ${prospectCreateActivityFragment}
  ${miitelCallPreleadActivityFragment}
  ${blastengineMailCampaignMailClickActivityFragment}
`;
