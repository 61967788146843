import React from 'react';

interface Props {
  label: string;
  parameter: string;
}

export default ({label, parameter}: Props) => {
  return (
    <div className="px-1 mb-2 w-fit bg-gray-50 rounded-sm">
      {label}: {parameter}
    </div>
  );
};
