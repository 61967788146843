import React, {useState} from 'react';
import styled from 'styled-components';
import {KeyPerson} from 'api';
import {DotMenuSide} from 'components/Ui/Icon';
import {Popover} from 'components/antd';
import EditModal from './EditModal';

interface Props {
  keyPerson: KeyPerson;
  accountPoolUuid: string;
}

const MenuCell = ({keyPerson, accountPoolUuid}: Props) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const Content = () => {
    return (
      <>
        <PopoverContent>
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsEditModalOpen(true);
              setIsPopoverVisible(false);
            }}>
            <span>編集</span>
          </div>
          {/* <div>
            <span>リードとして登録</span>
          </div> */}
        </PopoverContent>
        <EditModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          keyPerson={keyPerson}
          accountPoolUuid={accountPoolUuid}
        />
      </>
    );
  };

  return (
    <div className="ml-auto">
      <Popover
        placement="bottomRight"
        content={Content}
        trigger="click"
        visible={isPopoverVisible}
        onVisibleChange={(visible) => setIsPopoverVisible(visible)}>
        <div style={{cursor: 'pointer'}}>
          <DotMenuSide />
        </div>
      </Popover>
    </div>
  );
};

const PopoverContent = styled.div`
  width: 140px;
  font-size: 14px;
  color: var(--text-color-2);
  font-weight: normal;
  cursor: pointer;
  > div {
    padding: 4px;
    &:hover {
      background: #f3f5f7;
    }
    > span {
      margin-left: 4px;
    }
  }
`;

export default MenuCell;
