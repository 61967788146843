import React from 'react';
import Setting from './Setting';
import KeywordSetting from './KeywordSetting';
import Slider from './Slider';

export default () => {
  return (
    <div className="bg-white p-4 flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 items-start">
          <div className="flex w-full items-center justify-between">
            <div>
              <h2 className="text-base m-0 font-bold">求人・メディア</h2>
              <p className="m-0 text-sm">
                選択した求人・メディア種別の重要度を高めてスコアを算出します。また、重視するキーワードを設定できます。
              </p>
            </div>
            <Slider />
          </div>
          <Setting />
          <KeywordSetting />
        </div>
      </div>
    </div>
  );
};
